import React, { useState, useEffect, useCallback, useRef   } from 'react';
import { Row, Col, Form, Button, Nav, Tab, Spinner, Table, FormCheck, ButtonGroup, Modal } from 'react-bootstrap';
import { TagsInput } from "react-tag-input-component";
import Select from 'react-select';
import axios from 'axios';
import { getNames } from 'country-list';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Card from '../components/Card'
import DataTable from '../components/DataTable'
import uploadIcon from "../assets/images/upload.png";
import { setLeads } from '../store/leadSlice';
import { setUploadedData } from '../store/uploadedDataSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTrash, faRefresh, faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { v4 as uuidv4 } from 'uuid';
import { usePapaParse } from 'react-papaparse';
import CreatableSelect from 'react-select/creatable';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { toToastItem } from 'react-toastify/dist/utils';
import { SocialIcon } from 'react-social-icons'

// import { setFoundLeads } from '../store/foundLeadsSlice';
// import CheckboxBtn from '../components/setting/elements/checkbox-btn';

export default function BuildCampaign() {
    const [countries, setCountries] = useState([]);
    const [found_leads, setFoundLeads] = useState([])
    const [search_loading, setSearchLoading] = useState(false);
    const [next_loading, setNextLoading] = useState(false);
    const [enrich_loading, setEnrichLoading] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [page, setPage] = useState(0);
    const [total_page, setTotalPage] = useState(0);
    const [should_scroll, setShouldScroll] = useState(false);
    const [file_name, setFileName] = useState(null);
    const [leadSearchId, setLeadSearchId] = useState(null); 
    const [enrichDataQueue, setEnrichDataQueue] = useState([])
    const [exisitngIds, setExistingIds] = useState([])
    const [expandedIndex, setExpandedIndex] = useState(null);
    const [start_loading, setStartLoading] = useState(false);
    const [campaignName, setCampaignName] = useState("");
    const [campaignStartDate, setCampaignStartDate] = useState("");
    const [campaignStartTime, setCampaignStartTime] = useState("");
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedLead, setSelectedLead] = useState(null);
    const [source, setSource] = useState("search") //another option is upload
    const [loading, setLoading] = useState(false);
    const { jsonToCSV } = usePapaParse();
    const enrichDataQueueRef = useRef(enrichDataQueue);
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = '';  // For Chrome
            return '';  // For other browsers
        };
    
        if (found_leads.length > 0) {
            // Add event listener when found_leads has items
            window.addEventListener('beforeunload', handleBeforeUnload);
        } else {
            // Remove event listener when found_leads is empty
            window.removeEventListener('beforeunload', handleBeforeUnload);
        }
    
        // Cleanup: remove event listener when component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [found_leads]);

    


    const getApprovedData  = () =>{
        return enrichDataQueue.filter(lead => lead.approved === true);
    }

    const pendingData  = () =>{
        return enrichDataQueue.filter(lead => lead.approved === false);
    }

    const emailNotFetchedData = () =>{
        return enrichDataQueueRef.current.filter(lead => lead.email_content_generated === false);
    }

    const dispatch = useDispatch();
    const uploaded_data = useSelector((state) => state.uploaded_data);
    // const found_leads = useSelector((state) => state.found_leads);
    
    const navigate = useNavigate();
    const targetRef = useRef(null);

    const requiredColumns = ["First Name", "Last Name", "Title", "LinkedIn URL", "Email Address", "Company Name", "Company LinkedIn URL", "Company Website"];
    const uploaded_data_columns = requiredColumns.map(column => ({ title: column }));
    const convertUploadedDataToLeads = (data) => {
        return data.map(row => {
            return {
                "Company_LinkedIn_URL": row[6],
                "Company_Name": row[5],
                "Company_Website": row[7],
                "Email_Address": row[4],
                "First_Name": row[0],
                "Last_Name": row[1],
                "Full_Name": row[0] + ' '+ row[1],
                "Headline": row[2],
                "LinkedIn_URL": row[3],
                "Title": row[2],
                "Website_Domain": row[7]
            }
        });
    }
    
    const onDrop = useCallback(acceptedFiles => {
        const expectedColumns = ["First Name", "Last Name", "Title", "LinkedIn URL", "Company Name", "Company LinkedIn URL", "Company Website", "Email Address"]; // replace with your actual expected columns
    
        const file = acceptedFiles[0];
        setFileName(file.name);
        const reader = new FileReader();
    
        const fileExtension = file.name.split('.').pop().toLowerCase();
        const isValidFile = ['csv', 'xls', 'xlsx'].includes(fileExtension);
        
        if (!isValidFile) {
            toast.error("Only CSV, XLS, and XLSX files are allowed!")
            return;
        }
    
        reader.onload = function(e) {
            var contents = e.target.result;
    
            const validateColumns = (data) => {
                const fileColumns = Object.keys(data[0]);
                const missingColumns = expectedColumns.filter(col => !fileColumns.includes(col));
                return missingColumns.length === 0
            };
            const getMissingColumns = (data) => {
                const fileColumns = Object.keys(data[0]);
                const missingColumns = expectedColumns.filter(col => !fileColumns.includes(col));
                return missingColumns
            };
    
            const removeBOM = (text) => {
                if (text.charCodeAt(0) === 0xFEFF) {
                    return text.slice(1);
                }
                return text;
            };
    
            if(fileExtension === 'csv'){
                const cleanedCsvData = contents.replace(/^\s*ï»¿/, '');
                contents = removeBOM(cleanedCsvData.trim());
                Papa.parse(contents, {
                    header: true,
                    complete: function(results) {
                        if(validateColumns(results.data)){
                            const processedData = results.data.map(row => Object.values(row));
                            dispatch(setUploadedData(processedData));    
                        } else {
                            const missingColumns = getMissingColumns(results.data);
                            const missingColumnsText = `CSV file has missing or incorrect columns: ${missingColumns.join(', ')}`;
                            // Swal.fire({
                            //     icon: 'error',
                            //     title: 'Oops...',
                            //     text: missingColumnsText,
                            // });
                            toast.error(missingColumnsText);
                        }
                    }
                });
            }
            else {
                const workbook = XLSX.read(new Uint8Array(contents), { type: 'array' });
                const worksheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[worksheetName];
    
                const processedData = XLSX.utils.sheet_to_json(worksheet, {header: 1, defval: ""});
                const headerRow = processedData.shift();
                const dataRows = processedData.map(row => {
                    const rowData = {};
                    headerRow.forEach((col, index) => {
                        rowData[col] = row[index];
                    });
                    return rowData;
                });
    
                if(validateColumns(dataRows)){
                    const formattedData = dataRows.map(row => Object.values(row));
                    dispatch(setUploadedData(formattedData));
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'XLS/XLSX file has missing or incorrect columns!',
                    });
                }
            }
        };
    
        if(fileExtension === 'csv'){
          reader.readAsBinaryString(file);
        } else {
          reader.readAsArrayBuffer(file);
        }
    }, [dispatch]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
    const employee_range = [
        { value: '1, 10', label: '1 - 10' },
        { value: '11, 20', label: '11 - 20' },
        { value: '21, 50', label: '21 - 50' },
        { value: '51, 100', label: '51 - 100' },
        { value: '101, 200', label: '101 - 200' },
        { value: '201, 500', label: '201 - 500' },
        { value: '501, 1000', label: '501 - 1,000' },
        { value: '1001, 2000', label: '1,001 - 2,000' },
        { value: '2001, 5000', label: '2,001 - 5,000' },
        { value: '5001, 10000', label: '5,001 - 10,000' },
        { value: '10000+', label: '10,000+' }
    ];

    // console.log("Current Page:", page);
    useEffect(() => {
        const countryNames = getNames();
        const countryOptions = countryNames.map(name => ({ value: name, label: name }));
        setCountries(countryOptions);

        if(found_leads.length !== 0){
            setPage(1);
            setTotalPage(Math.ceil(found_leads.length / 100));
        }
    }, []);
    useEffect(() => {
        if (targetRef.current && should_scroll) {
            targetRef.current.scrollIntoView({ behavior: 'smooth' });
            setShouldScroll(false)
        }
    }, [should_scroll]);

    useEffect(() => {
        enrichDataQueueRef.current = enrichDataQueue;
      }, [enrichDataQueue]);

    const fetchData = async () => {
        const pendingIds = emailNotFetchedData(); // Function to get pending data
        console.log(pendingIds, enrichDataQueue);
  
        if (pendingIds.length === 0 ) {
          console.log('No pending IDs. Skipping fetchData.');
          return;
        }
        const ids = pendingIds.map(item => item.id);
  
        
       
        const user = JSON.parse(localStorage.getItem('actio_user'));
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/email-data`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${user.id}@@@${user.email}`,
            },
            body: JSON.stringify({ ids: ids }),
          });
  
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
  
          const resp = await response.json();
          const result = resp["data"];
  
          // Create a map of the response data for easy lookup
          const resultMap = result.reduce((acc, item) => {
            acc[item.id] = item.email_content;
            return acc;
          }, {});
  
          // Update the state with the email content from the API response
          setEnrichDataQueue(prevData => 
            prevData.map(item => {
              if (resultMap[item.id]) {
                return {
                  ...item,
                  email_content_generated: true,
                  email_content: resultMap[item.id]
                };
              }
              return item;
            })
          );
  
          setLoading(false);
        } catch (error) {
          console.error('Fetch error:', error);
          setLoading(false);
        }
      };

    useEffect(() => {
        // Fetch data immediately on component mount
        // fetchData();
    
        // Set up the interval to fetch data every 5 seconds
        const intervalId = setInterval(() => {
          fetchData();
        }, 5000);
    
        // Cleanup function to clear the interval on component unmount
        return () => clearInterval(intervalId);
    
      }, []); // Empty dependency array means this effect runs once on mount and cleans up on unmount
    
      
    const [formValues, setFormValues] = useState({
        person_locations: [],
        organization_num_employees_ranges: [],
        person_titles: [],
        q_organization_keyword_tags: []
    });
    
    const findLeads = async (page_number) => {

        var data = formValues;
        const user = JSON.parse(localStorage.getItem('actio_user'));
        data.page_number = page_number;
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/find_people', data,{
                headers: {
                    Authorization: `Bearer ${user.id}@@@${user.email}`, // Example: Authorization header with bearer token
                    // Add other headers as needed
                }
            });
            
            if(page_number === 1){
                setLeadSearchId(response.data.lead_search_id);
                setFoundLeads(response.data.data);
                setSelectedRows([])
                setExistingIds([])
            }
                
            else
               setFoundLeads(found_leads.concat(response.data.data));
            setSearchLoading(false);
            return true; // Indicate success
        } catch (error) {
            setSearchLoading(false);
            toast.error("Search failed, Please try again")
            // Swal.fire({
            //     title: 'Fail!',
            //     text: 'Search Failed',
            //     icon: 'error',
            //     showCancelButton: false,
            //     confirmButtonText: 'OK',
            //     confirmButtonColor: '#457CE7',
            // });
    
            return false; // Indicate failure
        }
    }

    const handleJsonToCSV = () => {
        const jsonData = getApprovedData()
        const filteredData = jsonData.map(entry => ({
            "id": entry.id,
            "Full Name": entry.name,
            "Title": entry.title,
            "Email Address": entry.email,
            "LinkedIn URL": entry.linkedin_url,
            "Email Content": entry.email_content
            
        }));
    
        const results = jsonToCSV(filteredData);
        
        // Create a Blob from the CSV string
        const blob = new Blob([results], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
    
        // Create a temporary link element
        const link = document.createElement('a');
        link.href = url;
        const fileName = campaignName + ".csv"
        link.setAttribute('download', fileName);
    
        // Append the link to the document body and trigger the download
        document.body.appendChild(link);
        link.click();
    
        // Clean up the link element
        document.body.removeChild(link);
      };
    

    const handleInputChange = (name, value) => {
        setFormValues(prevState => ({ ...prevState, [name]: value }));
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        const allEmpty = Object.values(formValues).every(arr => arr.length === 0);
        if (allEmpty) {
            toast.error('All values are empty.');
            return
        }
        
        setSearchLoading(true);

        const success = await findLeads(1);
        if (success) {
            setPage(1);
            setTotalPage(1);
            setShouldScroll(true);
            setSelectedRows([])
        }  
    };
    const nextPage = async () => {
        if(total_page === page){
            const current_page = page;
            setNextLoading(true);
            const success = await findLeads(current_page + 1);
            if (success) {
                setPage(current_page + 1);
                setTotalPage(current_page + 1);
            } 
            setNextLoading(false); 
        }
        else
            setPage(page + 1)
    }
    const handleLeadCheckboxClick = (index) => {
        setSelectedRows(prevState => {
            if (prevState.includes(index)) {
                return prevState.filter(i => i !== index);
            } else {
                return [...prevState, index];
            }
        });
    };

    const handleStartCampaign = (event) => {
        event.preventDefault();
        if (!campaignName || !campaignStartDate || !campaignStartTime) {
            toast.error("Please fill in all required campaign fields.");
            return;
        }
        if (getApprovedData()){
            const isoDate =  new Date(`${campaignStartDate}T${campaignStartTime}:00`).toISOString();
            const data_for_campaign = getApprovedData().map((lead, index) => ({
                "id" : lead["id"],
                "First Name": lead["first_name"],
                "Last Name": lead["last_name"],
                "Email Address": lead['email'],
                "Company Name": lead["employment_history"] ? lead["employment_history"][0]["organization_name"]: null ,
                "LinkedIn URL": lead['linkedin_url'],
                // "Website": lead["scrapped_data"]['Company Website'],
                "Email Content": lead['email_content']
            }));
        const data = {
            "name": campaignName,
            "start_time": isoDate,
            "df": data_for_campaign
        };
        setStartLoading(true);
        const user = JSON.parse(localStorage.getItem('actio_user'));
        axios.post(process.env.REACT_APP_API_URL + '/start_campaign', data, {
            headers: {
                Authorization: `Bearer ${user.id}@@@${user.email}`, // Example: Authorization header with bearer token
                // Add other headers as needed
            }
        })
        .then((response) => {
            setStartLoading(false);
            handleJsonToCSV()
            setFoundLeads([])
            setPage(0)
            setSelectedRows([])
            setEnrichDataQueue([])
            setExistingIds([])
            setSelectedLead(null)
            setLeadSearchId(null)

            localStorage.removeItem('actio_user_campaign_data');
            toast.success("Campaign Started Successfully")
            
            setTimeout(() => {
                window.location.href = '/campaign-list'; // Redirect to campaign list page
            },3000); // 4 seconds delay
        })
        .catch((error) => {
            setStartLoading(false);
            toast.error("Campaign Start Failed")
        });

        }
        
    };

    // const handleAddToCampaign = () => {
    //     const updatedEnrichDataQueue = [...enrichDataQueue];      
    //     const selected_leads = selectedRows.map(index => {
    //         const lead = found_leads[index];
    //         // if these keys are present return lead if not add these keys
    //         const existingLeadIndex = updatedEnrichDataQueue.findIndex(q => q.fe_id === lead.fe_id);
    
    //         if (existingLeadIndex === -1) {
    //             // If lead is not found in enrichDataQueue, add it with additional keys
    //             return {
    //                 ...lead,
    //                 index: index,
    //                 email_generated: false,
    //                 email: null,
    //                 email_content_generated: null,
    //                 email_content: null,
    //                 approved: false
    //             };
    //         } else {
    //             // If lead is already in enrichDataQueue, return it unchanged
    //             return updatedEnrichDataQueue[existingLeadIndex];
    //         }
    //     });
    
    //     const difference = selectedRows.filter(id => !exisitngIds.includes(id));
    //     const updatedIds = [...new Set([...exisitngIds, ...selectedRows])];
    //     setExistingIds(updatedIds);
    //     console.log(difference, selectedRows, exisitngIds, "lead_id");
    
    //     const user = JSON.parse(localStorage.getItem('actio_user'));
    //     const leadsToEnrich = difference.map(id => found_leads[id]);
    
    //     fetch(`${process.env.REACT_APP_API_URL}/enrich/${leadSearchId}?source=${source}`, {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             Authorization: `Bearer ${user.id}@@@${user.email}`,
    //         },
    //         body: JSON.stringify(leadsToEnrich), // Send the entire array of leads
    //         credentials: 'include',
    //     })
    //     .then(response => {
    //         if (!response.ok) {
    //             throw new Error('Failed to enrich data');
    //         }
    //         return response.json();
    //     })
    //     .then(response => {
    //         console.log(response.data, "response.data")
    //         const updatedLeads = response.data.map((res, index) => {
    //             const lead = leadsToEnrich[index];
    //             return {
    //                 ...lead,
    //                 approved: false,
    //                 email_generated: true, // Assuming email generation was successful
    //                 email: res.email, // Update with the enriched email
    //                 email_content_generated: false, // Assuming content generation was successful
    //                 email_content: null // Update with the enriched email content
    //             };
    //         });
    
    //         const updatedLeadIds = updatedLeads.map(lead => lead.fe_id);
    //         const newEnrichDataQueue = updatedEnrichDataQueue.filter(lead => !updatedLeadIds.includes(lead.fe_id));
    //         setEnrichDataQueue([...newEnrichDataQueue, ...updatedLeads]);
    
    //         console.log('Enriched data for leads:', response);
    //     })
    //     .catch(error => {
    //         console.error('Error enriching data:', error);
    //         Swal.fire({
    //             title: 'Fail!',
    //             text: error.message,
    //             icon: 'error',
    //             showCancelButton: false,
    //             confirmButtonText: 'OK',
    //             confirmButtonColor: '#457CE7',
    //         });
    //     })
    //     .finally(() => {
    //         setEnrichLoading(false); // Set loading state to false regardless of success or failure
    //     });
    // };

    const handleAddToCampaign = async () => {
        setEnrichLoading(true);
        setLoading(true);
    
        const difference = selectedRows.filter(id => !exisitngIds.includes(id));
        const updatedIds = [...new Set([...exisitngIds, ...selectedRows])];
        setExistingIds(updatedIds);
    
        const user = JSON.parse(localStorage.getItem('actio_user'));
        const leadsToEnrich = difference.map(id => found_leads[id]);
    
        // Create a map of leads by fe_id
        const leadsMap = new Map(leadsToEnrich.map(lead => [lead.fe_id, lead]));
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/enrich/${leadSearchId}?source=${source}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.id}@@@${user.email}`,
                },
                body: JSON.stringify(leadsToEnrich),
                credentials: 'include',
            });
    
            if (!response.ok) {
                setLoading(false);
                throw new Error('Failed to enrich data');
            }
    
            const result = await response.json();
            const enrichedLeads = result.data.map(res => {
                const lead = leadsMap.get(res.fe_id); // Get lead via fe_id
                return {
                    ...lead,
                    "Company LinkedIn Data": res["Company LinkedIn Data"],
                    "Current Company LinkedIn URL": res["Current Company LinkedIn URL"],
                    "Current Company Name": res["Current Company Name"],
                    "LinkedIn Data": res["LinkedIn Data"],
                    approved: false,
                    email_generated: true,
                    email: res.email,
                    id: res.id,
                    email_content_generated: false,
                    email_content: null
                };
            });
    
            // Simply concatenate the new leads with the existing queue
            setEnrichDataQueue(prevQueue => [...prevQueue, ...enrichedLeads]);
    
        } catch (error) {
            console.error('Error enriching data:', error);
            toast.error(error.message);
        } finally {
            setLoading(false);
            setEnrichLoading(false);
        }
    };
    

    const handleDoubleClick = (index) => {
        setExpandedIndex(index === expandedIndex ? null : index);
    };

    const handleRetry = (lead) => {
        // Mark email_content_generated as false before triggering the API call
        setEnrichDataQueue(prevQueue => {
            const leadIndex = prevQueue.findIndex(q => q.fe_id === lead.fe_id);
            if (leadIndex !== -1) {
                const newQueue = [...prevQueue];
                newQueue[leadIndex] = { ...newQueue[leadIndex], email_content_generated: false, email_content: null};
                return newQueue;
            }
            return prevQueue;
        });
        const user = JSON.parse(localStorage.getItem('actio_user'));
        fetch(`${process.env.REACT_APP_API_URL}/fetch-email-content/${lead.id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.id}@@@${user.email}`,
            },
            credentials: 'include',
            body: JSON.stringify({ lead })
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`Failed to fetch email content for lead ${lead.id}`);
            }
            return response.json();
        })
        .then(response => {
            const ids = [lead.id]
            const user = JSON.parse(localStorage.getItem('actio_user'));
                try {
                const response = fetch(`${process.env.REACT_APP_API_URL}/email-data`, {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.id}@@@${user.email}`,
                    },
                    body: JSON.stringify({ ids: ids }),
                });
        
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
        
                const resp = response.json();
                const result = resp["data"];
        
                // Create a map of the response data for easy lookup
                const resultMap = result.reduce((acc, item) => {
                    acc[item.id] = item.email_content;
                    return acc;
                }, {});
        
                // Update the state with the email content from the API response
                setEnrichDataQueue(prevData => 
                    prevData.map(item => {
                    if (resultMap[item.id]) {
                        return {
                        ...item,
                        email_content_generated: true,
                        email_content: resultMap[item.id]
                        };
                    }
                    return item;
                    })
                );
        
                setLoading(false);
                } catch (error) {
                console.error('Fetch error:', error);
                setLoading(false);
                }
            // const updatedLead = {
            //     ...lead,
            //     email_content_generated: true,
            //     email_content: response.data.scrapped_data,
            // };
    
            // // Update the enrichDataQueue state with the new content
            // setEnrichDataQueue(prevQueue => {
            //     const leadIndex = prevQueue.findIndex(q => q.fe_id === lead.fe_id);
            //     if (leadIndex !== -1) {
            //         const newQueue = [...prevQueue];
            //         newQueue[leadIndex] = updatedLead;
            //         return newQueue;
            //     }
            //     return prevQueue;
            // });
    
            // Remove the lead ID from pending requests
        })
        .catch(error => {

            console.error(`Error fetching email content for lead ${lead.fe_id}:`, error);
            // Remove the lead ID from pending requests even if there's an error
        });
    };

    const handleEmailContentChange = (e) =>{
        setSelectedLead({
            ...selectedLead,
            email_content: e.target.value
        });
    }

    const handleAccept = (lead) =>{
        setEnrichDataQueue((prevQueue) =>
            prevQueue.map((item) =>
                item.fe_id === lead.fe_id
                    ? { ...item, approved: true }
                    : item
            )
        );
    }

    const handleEditClick = (lead) => {
        setSelectedLead(lead);
        setShowEditModal(true);
    };

    const handleEdit = () => {

        console.log(selectedLead, "email_content")

        const updatedLead = {
            ...selectedLead,
            email_content: selectedLead.email_content // Ensure updated email content is included
        };
        setEnrichDataQueue((prevQueue) => {
            // Replace the item with the updated one
            return prevQueue.map((item) =>
                item.fe_id === selectedLead.fe_id ? updatedLead : item
            );
        });
        setShowEditModal(false);
        setSelectedLead(null);
    

    };


    const handleDelete = () => {
        const lead = selectedLead
        const existingIndex = found_leads.findIndex(item => item.fe_id === lead.fe_id);
        const updatedRows = selectedRows.filter(index => index !== existingIndex);
        setEnrichDataQueue((prevQueue) =>
            prevQueue.filter((item) => item.fe_id !== lead.fe_id)
        );
        setSelectedRows(updatedRows)
        setShowDeleteModal(false);
        setSelectedLead(null);
    };



    const handleDeleteClick = (lead) => {
        setSelectedLead(lead);
        setShowDeleteModal(true);
    };
    
    const handleAddToCampaignFromUpload = () => {
        const leads_to_generate = convertUploadedDataToLeads(uploaded_data);
        const user = JSON.parse(localStorage.getItem('actio_user'));
        fetch(process.env.REACT_APP_API_URL + '/save_uploaded', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${user.id}@@@${user.email}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(leads_to_generate),
            credentials: 'include', // Include credentials (cookies) with the request
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json(); // Parse JSON response asynchronously
        })
        .then((response) => {
            const leadSearchId = response.lead_search_id
            setLeadSearchId(leadSearchId)
            
            const leadData = leads_to_generate.map(item => ({
                first_name: item.First_Name,
                last_name: item.Last_Name,
                name: item.Full_Name,
                linkedin_url: item.LinkedIn_URL,
                title: item.Title,
                email_status: "verified",
                headline: item.Headline,
                email: item.Email_Address,
                employment_history: [
                    {
                        current: true,
                        organization_name: item.Company_Name,
                        start_date: null,  // No start date provided in the input
                        title: item.Title
                    }
                ],
                organization: {
                    website_url: `http://${item.Company_Website}`,
                    company_linkedin_url: item.Company_LinkedIn_URL
                },
                fe_id: uuidv4()
            }))

            setSource("csv")
            setFoundLeads(leadData)
            setPage(1)

        });

        

       
    }
    var page_btns=[];
    for(let i = 1 ; i <= total_page ; i++){
        page_btns.push(<Button key={i} className="px-3" variant={"icon " + (i === page ? "btn-primary" : "btn-outline-primary")} onClick={() => setPage(i)} >{i}</Button>)
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const campaignId = queryParams.get('id');

        if (campaignId) {
            // Fetch data based on campaignId
            const user = JSON.parse(localStorage.getItem('actio_user'));
            const fetchData = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/update_data/${campaignId}`, {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${user.id}@@@${user.email}`,
                            'Content-Type': 'application/json',
                        },
                        credentials: 'include', // Include credentials (cookies) with the request
                    });

                    let data = await response.json();
                    data= data.data.lead_data.data
                    console.log(data, "resp_data");
                    setFoundLeads(data.found_leads);
                    setSelectedRows(data.selectedRows);
                    setPage(1);
                    setTotalPage(Math.ceil(data.found_leads.length / 100));
                    setFileName(data.file_name);
                    setEnrichDataQueue(data.enrichDataQueue);
                    setExistingIds(data.existingIds);
                    setSource(data.source);
                    setSelectedLead(data.selectedLead);
                    setLeadSearchId(data.leadSearchId);
                } catch (error) {
                    console.error('Error fetching campaign data:', error);
                }
            };

            fetchData();
        }
    }, []);

    useEffect(() =>{
        if (page !== 0){

            const localData ={
                "found_leads" : found_leads,
                "selectedRows" : selectedRows,
                "total_page" : total_page,
                "file_name" : file_name,
                
                "enrichDataQueue" : enrichDataQueue,
                "exisitngIds" : exisitngIds,
                "source" : source,
                "selectedLead" : selectedLead,
                "leadSearchId" : leadSearchId
            }

            const user = JSON.parse(localStorage.getItem('actio_user'));
            try {
                const response = fetch(`${process.env.REACT_APP_API_URL}/update_data/${leadSearchId}`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${user.id}@@@${user.email}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ data: localData }),
                    credentials: 'include', // Include credentials (cookies) with the request
                });
        
                // Check if the response is successful
                if (!response.ok) {
                    throw new Error(`Error: ${response.status} - ${response.statusText}`);
                }
        
                // Optionally, handle the response if needed
                const result = response.json();
                console.log('Data updated successfully:', result);
            } catch (error) {
                console.error('Error updating data:', error);
            }


            // localStorage.setItem('actio_user_campaign_data', JSON.stringify(local_data));

        }
        
    },[found_leads, selectedRows, total_page, file_name,page,
        enrichDataQueue, exisitngIds, source,selectedLead])


    return (
        <>
        <Row className="">                  
            <Col lg="12" className="">
                <Card className="rounded-5">
                    <Card.Body className="">   
                    <ToastContainer />
                        <Tab.Container defaultActiveKey="first">  
                        <Nav variant="pills" defaultActiveKey="first">
                                <Nav.Item style={{width:"200px", textAlign:"center"}}>
                                    <Nav.Link eventKey="first">Find Leads</Nav.Link>
                                </Nav.Item>
                                <Nav.Item style={{width:"200px", textAlign:"center"}}>
                                    <Nav.Link eventKey="second">Upload</Nav.Link>
                                </Nav.Item>
                            </Nav>   
                            <Tab.Content className="p-4">
                                <Tab.Pane eventKey="first">
                                    <Form onSubmit={handleSubmit} className="col-md-9 row">
                                        <Form.Group className="mb-4 col-6">
                                            <Form.Label>Location</Form.Label>
                                            <CreatableSelect
                                                isMulti
                                                options={countries}
                                                onChange={selectedOptions => handleInputChange('person_locations', selectedOptions.map(option => option.value))}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-4 col-6">
                                            <Form.Label>Number of Employees Ranges</Form.Label>
                                            <Select 
                                                isMulti 
                                                options={employee_range} 
                                                onChange={selectedOptions => handleInputChange('organization_num_employees_ranges', selectedOptions.map(option => option.value))}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-4 col-6">
                                            <Form.Label>Titles</Form.Label>
                                            <TagsInput 
                                                placeHolder='Enter Titles'
                                                onChange={value => handleInputChange('person_titles', value)}
                                                separators={["Enter"]}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-4 col-6">
                                            <Form.Label>Organization Keywords</Form.Label>
                                            <TagsInput 
                                                placeHolder='Enter Organization Keywords'
                                                onChange={value => handleInputChange('q_organization_keyword_tags', value)}
                                                separators={["Enter"]}
                                            />
                                        </Form.Group>
                                        <div className="mt-3" style={{textAlign:"center"}}>
                                            <Button variant="primary rounded-pill" type="submit" className="pill-button" disabled={search_loading}>
                                                {search_loading ? <Spinner animation="border" size="sm" /> : <><i className="fas fa-search"></i> <span style={{marginLeft:"5px"}}>Search</span></>} 
                                            </Button>
                                        </div>
                                    </Form>
                                    
                                    
                                    {page === 0 ? "":
                                    <div>
                                        <div className="mt-5 mb-3"><h5>Leads Found</h5></div>
                                        <div style={{ maxHeight: "500px", width: "100%", overflowX: "auto", overflowY: "auto" }}>
                                            <Table className="table table-hover">
                                                <thead className="sticky-header">
                                                    <tr>
                                                        <th>*</th>
                                                        <th>No</th>
                                                        <th>First Name</th>
                                                        <th>Last Name</th>
                                                        <th>Title</th>
                                                        <th>LinkedIn</th>
                                                        <th>Company Name</th>
                                                        <th>Company Data</th>
                                                        {/* <th>Company LinkedIn URL</th>
                                                        <th>Company Website</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {found_leads.length == 0 ? 
                                                        <tr>
                                                            <td colSpan="10" className="text-center no-results">
                                                                No Results Found
                                                            </td>
                                                        </tr> :
                                                        found_leads.slice((page - 1) * 100, page * 100).map((lead, index) => (
                                                            <tr key={index} className="table-row">
                                                                <td className="table-cell">
                                                                    <FormCheck checked={selectedRows.includes((page - 1) * 100 + index)} onChange={() => handleLeadCheckboxClick((page - 1) * 100 + index)}/>
                                                                </td>
                                                                <td className="table-cell">
                                                                    {(page - 1) * 100 + index + 1}
                                                                </td>
                                                                <td className="table-cell">
                                                                    {lead.first_name}
                                                                </td>
                                                                <td className="table-cell">
                                                                    {lead.last_name}
                                                                </td>
                                                                <td className="table-cell">
                                                                    {lead.title}
                                                                </td>
                                                                <td className="table-cell">
                                                                    {/* {lead.linkedin_url ? <a href={lead.linkedin_url} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} /> </a> : ""} */}
                                                                    {lead.linkedin_url ? <SocialIcon style={{"width" : "30px", "height" : "30px"}} target='_blank' url={lead.linkedin_url} /> : ""}
                                                                </td>
                                                                <td className="table-cell">
                                                                    {lead.employment_history.length > 0 ? lead.employment_history[0].organization_name : null}
                                                                </td>
                                                                <td className="table-cell">
                                                                {lead.organization?.company_linkedin_url  ? <SocialIcon style={{"width" : "30px", "height" : "30px", marginRight : "5px"}} target='_blank' url={lead.organization?.company_linkedin_url} /> : ""}
                                                                {lead.organization?.website_url ? <SocialIcon style={{"width" : "30px", "height" : "30px"}} target='_blank' url={lead.organization?.website_url} /> : ""}
                                                                </td>
                                                                {/* <td className="table-cell">
                                                                    {lead.organization?.company_linkedin_url ? <a href={lead.organization?.company_linkedin_url} target="_blank" rel="noopener noreferrer"> {lead.organization?.company_linkedin_url}</a> : ""}
                                                                </td>
                                                                <td className="table-cell">
                                                                    {lead.organization?.website_url ? <a href={lead.organization?.website_url} target="_blank" rel="noopener noreferrer"> {lead.organization?.website_url}</a> : ""}
                                                                </td> */}
                                                            </tr>

                                                        ))
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                        
                                        <div ref={targetRef} className="text-end mt-4">
                                            <ButtonGroup role="group" aria-label="First group">
                                                <Button className="px-3" variant="icon btn-outline-primary" onClick={() => setPage(page - 1)} disabled={page <= 1 }>Previous</Button>
                                                {page_btns}
                                                <Button className="px-3" variant="icon btn-outline-primary" onClick={nextPage} disabled={ found_leads.length % 100 != 0 || page === 5 || page === 0 || next_loading}>
                                                    {next_loading ? <Spinner animation="border" size="sm" /> : <>Next</>}
                                                </Button>
                                            </ButtonGroup>
                                        </div>
                                        <div className="text-center mt-3">
                                            <Button variant="primary rounded-pill" onClick={handleAddToCampaign} disabled={enrich_loading || selectedRows.length === 0}>
                                                {enrich_loading ? <Spinner animation="border" size="sm" /> : <><i className="fas fa-hammer"></i> <span style={{marginLeft:"5px"}}>Add To Campaign</span></>}   
                                            </Button>
                                        </div>
                                    </div>

                                    }

                                {pendingData().length === 0 ? "":
                                    <div>
                                        <div className="mt-5 mb-3"><h5>Review Result</h5></div>
                                        <div style={{ maxHeight: "500px", width: "100%", overflowX: "auto", overflowY: "auto" }}>
                                            <Table className="table table-hover">
                                                <thead className="sticky-header">
                                                    <tr>
                                                        <th>Full Name</th>
                                                        <th>Title</th>
                                                        <th>Email Address</th>
                                                        <th>LinkedIn</th>
                                                        <th>Email</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {pendingData().length == 0 ? 
                                                        <tr>
                                                            <td colSpan="10" className="text-center no-results">
                                                                No Results Found
                                                            </td>
                                                        </tr> :
                                                        pendingData().slice((page - 1) * 100, page * 100).map((lead, index) => (
                                                            <tr key={index}>
                                                                <td className="table-cell">{lead.first_name} {lead.last_name}</td>
                                                                <td className="table-cell">{lead.title}</td>
                                                                <td className="table-cell">{lead.email === null ? (
                                                                        <FontAwesomeIcon icon={faSpinner} spin />
                                                                        ) : (
                                                                        lead.email
                                                                        )}</td>
                                                                {/* <td className="table-cell">{lead.linkedin_url ? <a href={lead.linkedin_url} target="_blank"> {lead.linkedin_url}</a> : ""}</td> */}
                                                                <td className="table-cell">
                                                                    {/* {lead.linkedin_url ? <a href={lead.linkedin_url} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} /> </a> : ""} */}
                                                                    {lead.linkedin_url ? <SocialIcon style={{"width" : "30px", "height" : "30px"}} target='_blank' url={lead.linkedin_url} /> : ""}
                                                                </td>
                                                                <td className="table-cell">
                                                                {lead.email_content === null ? (
                                                                    <FontAwesomeIcon icon={faSpinner} spin />
                                                                ) : (
                                                                    <div className={`expandable-cell ${expandedIndex === index ? 'expanded' : ''}`} onDoubleClick={() => handleDoubleClick(index)}>
                                                                        {/* {lead.email_content.trim()} */}
                                                                        {/* //Add a trim logic here// */}
                                                                        {lead.email_content} 
                                                                    </div>
                                                                )}
                                                            </td>
                                                            <td>
                                                            <div onClick={() => handleEditClick(lead)} style={{ cursor: 'pointer', marginRight: '15px', display: 'inline-block' }} title="Edit">
                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                </div>
                                                                
                                                                <div onClick={() => handleDeleteClick(lead)} style={{ cursor: 'pointer', marginRight: '15px', display: 'inline-block', color: 'red' }} title="Delete">
                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                </div>
                                                                
                                                                <div onClick={() => handleRetry(lead)} style={{ cursor: 'pointer', marginRight: '15px', display: 'inline-block', color: 'blue' }} title="Refresh">
                                                                    <FontAwesomeIcon icon={faRefresh} />
                                                                </div>

                                                                <div onClick={() => handleAccept(lead)} style={{ cursor: 'pointer', display: 'inline-block', color: 'green' }} title="Accept">
                                                                    <FontAwesomeIcon icon={faCheck} />
                                                                </div>
                                                            </td>

                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                        
                                        <div ref={targetRef} className="text-end mt-4">
                                            <ButtonGroup role="group" aria-label="First group">
                                                <Button className="px-3" variant="icon btn-outline-primary" onClick={() => setPage(page - 1)} disabled={page <= 1 }>Previous</Button>
                                                {page_btns}
                                                <Button className="px-3" variant="icon btn-outline-primary" onClick={nextPage} disabled={ found_leads.length % 100 != 0 || page === 5 || page === 0 || next_loading}>
                                                    {next_loading ? <Spinner animation="border" size="sm" /> : <>Next</>}
                                                </Button>
                                            </ButtonGroup>
                                        </div>
                                    </div>

                                    }

                                {getApprovedData().length === 0 ? "":
                                <div>
                                    <div className="mt-5 mb-3"><h5>Reviewed Data</h5></div>
                                    <div style={{ maxHeight: "500px", width: "100%", overflowX: "auto", overflowY: "auto" }}>
                                        <Table className="table table-hover">
                                            <thead className="sticky-header">
                                                <tr>
                                                    <th>Full Name</th>
                                                    <th>Title</th>
                                                    <th>Email Address</th>
                                                    <th>LinkedIn</th>
                                                    <th>Email</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {getApprovedData().length == 0 ? 
                                                    <tr>
                                                        <td colSpan="10" className="text-center no-results table-cell">
                                                            No Results Found
                                                        </td>
                                                    </tr> :
                                                    getApprovedData().slice((page - 1) * 100, page * 100).map((lead, index) => (
                                                        <tr key={index}>
                                                            <td className="table-cell">{lead.first_name} {lead.last_name}</td>
                                                            <td className="table-cell">{lead.title}</td>
                                                            <td className="table-cell">{lead.email === null ? (
                                                                    <FontAwesomeIcon icon={faSpinner} spin />
                                                                    ) : (
                                                                    lead.email
                                                                    )}</td>
                                                            {/* <td className="table-cell">{lead.linkedin_url ? <a href={lead.linkedin_url} target="_blank"> {lead.linkedin_url}</a> : ""}</td> */}
                                                            <td className="table-cell">
                                                                    {/* {lead.linkedin_url ? <a href={lead.linkedin_url} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} /> </a> : ""} */}
                                                                    {lead.linkedin_url ? <SocialIcon style={{"width" : "30px", "height" : "30px"}} target='_blank' url={lead.linkedin_url} /> : ""}
                                                                </td>
                                                            <td className="table-cell">
                                                            {lead.email_content === null ? (
                                                                <FontAwesomeIcon icon={faSpinner} spin />
                                                            ) : (
                                                                <div className={`expandable-cell ${expandedIndex === index ? 'expanded' : ''}`} onDoubleClick={() => handleDoubleClick(index)}>
                                                                    {lead.email_content.trim()}
                                                                </div>
                                                            )}
                                                        </td>
                                                        <td>
                                                        <div onClick={() => handleEditClick(lead)} style={{ cursor: 'pointer', marginRight: '15px', display: 'inline-block' }} title="Edit">
                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                </div>
                                                                <div onClick={() => handleDeleteClick(lead)} style={{ cursor: 'pointer', marginRight: '15px', display: 'inline-block', color: 'red' }} title="Delete">
                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                </div>
                                                                
                                                                <div onClick={() => handleRetry(lead)} style={{ cursor: 'pointer', marginRight: '15px', display: 'inline-block', color: 'blue' }} title="Refresh">
                                                                    <FontAwesomeIcon icon={faRefresh} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                        
                                    </div>
                                    
                                    <div ref={targetRef} className="text-end mt-4">
                                        <ButtonGroup role="group" aria-label="First group">
                                            <Button className="px-3" variant="icon btn-outline-primary" onClick={() => setPage(page - 1)} disabled={page <= 1 }>Previous</Button>
                                            {page_btns}
                                            <Button className="px-3" variant="icon btn-outline-primary" onClick={nextPage} disabled={ found_leads.length % 100 != 0 || page === 5 || page === 0 || next_loading}>
                                                {next_loading ? <Spinner animation="border" size="sm" /> : <>Next</>}
                                            </Button>
                                        </ButtonGroup>
                                    </div>

                                                <div className="text-center mt-3">
                                                <Form className="row">
                                                    <div className="col-6">
                                                        <Form.Group className="mb-3" controlId="campaignName">
                                                            <Form.Label>Campaign Name</Form.Label>
                                                            <Form.Control type="text" placeholder="Enter campaign name" value={campaignName} onChange={e => setCampaignName(e.target.value)}/>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-3">
                                                        <Form.Group className="mb-3" controlId="campaignStartDate">
                                                            <Form.Label>Campaign Start Date</Form.Label>
                                                            <Form.Control type="date" placeholder="Enter campaign start date" value={campaignStartDate} onChange={e => setCampaignStartDate(e.target.value)}/>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-3">
                                                        <Form.Group className="mb-3" controlId="campaignStartDate">
                                                            <Form.Label>Campaign Start Time</Form.Label>
                                                            <Form.Control type="time" placeholder="Enter campaign start time" value={campaignStartTime} onChange={e => setCampaignStartTime(e.target.value)}/>
                                                        </Form.Group>
                                                    </div>
                                                </Form>
                                        
                                                <Button style={{ marginLeft: "5px" }}
                                                        variant="primary rounded-pill" 
                                                        onClick={handleStartCampaign} 
                                                        className="pill-button" 
                                                        disabled={start_loading || getApprovedData().length === 0}
                                                        >
                                                        {start_loading ? 
                                                            <Spinner animation="border" size="sm" /> : 
                                                            <>
                                                            <i className="fas fa-paper-plane"></i> 
                                                            <span style={{ marginLeft: "5px" }}>Start Campaign</span>
                                                            </>
                                                        }
                                                        </Button>

                                                        <Button style={{ marginLeft: "5px" }}
                                                        variant="primary rounded-pill" 
                                                        onClick={handleJsonToCSV} 
                                                        className="pill-button" 
                                                        disabled={getApprovedData().length === 0}
                                                        >
                                                        {start_loading ? 
                                                            <Spinner animation="border" size="sm" /> : 
                                                            <>
                                                            <i className="fas fa-download"></i> 
                                                            <span style={{ marginLeft: "5px" }}>Export Campaign</span>
                                                            </>
                                                        }
                                                        </Button>
                                    </div>
                                </div>
                                }
                                <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Confirm Deletion</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            Are you sure you want to delete this item?
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                                                Cancel
                                            </Button>
                                            <Button variant="danger" onClick={handleDelete}>
                                                Delete
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>

                                    {showEditModal ? <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Confirm Edit</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form>
                                            <Form.Group controlId="formFullName">
                                                <Form.Label>Full Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={selectedLead.name}
                                                    readOnly
                                                    style={{ backgroundColor: '#f8f9fa' }} // Light gray background for read-only
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="formTitle">
                                                <Form.Label className="mt-3">Title</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={selectedLead.title}
                                                    readOnly
                                                    style={{ backgroundColor: '#f8f9fa' }} // Light gray background for read-only
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="formEmail">
                                                <Form.Label className="mt-3">Email</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    value={selectedLead.email}
                                                    readOnly
                                                    style={{ backgroundColor: '#f8f9fa' }} // Light gray background for read-only
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="formEmailContent">
                                                <Form.Label className="mt-3">Email Content</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={5}
                                                    value={selectedLead.email_content}
                                                    onChange={handleEmailContentChange}
                                                    style={{ backgroundColor: '#ffffff' }} // White background for editable
                                                />
                                            </Form.Group>
                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                                            Cancel
                                        </Button>
                                        <Button variant="primary" onClick={handleEdit}>
                                            Save
                                        </Button>
                                    </Modal.Footer>
                                </Modal> : ""}
                                
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <div className="d-flex justify-content-end px-4 mb-3">
                                        <a className="btn btn-primary rounded-pill" href="template.csv" download>
                                            <span className="btn-inner">
                                                <svg fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-32" width="32" height="32" viewBox="0 0 24 24"><path d="M12.1221 15.436L12.1221 3.39502" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M15.0381 12.5083L12.1221 15.4363L9.20609 12.5083" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M16.7551 8.12793H17.6881C19.7231 8.12793 21.3721 9.77693 21.3721 11.8129V16.6969C21.3721 18.7269 19.7271 20.3719 17.6971 20.3719L6.55707 20.3719C4.52207 20.3719 2.87207 18.7219 2.87207 16.6869V11.8019C2.87207 9.77293 4.51807 8.12793 6.54707 8.12793L7.48907 8.12793" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                            </span>
                                            <span style={{marginLeft:"10px"}}>Download Sample</span>
                                        </a>
                                    </div>
                                    
                                    <div {...getRootProps()} style={{ border: '2px dotted gray', padding: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                        <input {...getInputProps()} />
                                        {isDragActive ?
                                            <>Drop the files here ... </>:
                                            <><img src={uploadIcon} style={{width:"70px", marginRight:"20px"}}/>Click here to select file</>
                                        }
                                    </div>
                                    {found_leads.length == 0 ? 
                                    <>
                                    <div className="text-left mt-4">
                                        <h5>{file_name}</h5>
                                    </div>
                                    
                                    {
                                        uploaded_data.length === 0 ? "" :
                                        <>
                                        <div className="mt-3">
                                            <DataTable
                                                title="Uploaded Data"
                                                columns={uploaded_data_columns}
                                                data={uploaded_data}
                                            />
                                        </div>
                                        <div className="text-center mt-4">
                                            <Button variant="primary rounded-pill" onClick={handleAddToCampaignFromUpload}>
                                                <i className="fas fa-hammer"></i> <span style={{marginLeft:"5px"}}>Load Leads</span>
                                            </Button>
                                        </div>
                                        </>
                                    }
                                    </> : null
                                }
                                    {found_leads.length > 0 ? 
                                        <div>
                                        <div className="mt-5 mb-3"><h5>Leads Found</h5></div>
                                        <div style={{ maxHeight: "500px", width: "100%", overflowX: "auto", overflowY: "auto" }}>
                                            <Table className="table table-hover">
                                                <thead className="sticky-header">
                                                    <tr>
                                                        <th>*</th>
                                                        <th>No</th>
                                                        <th>First Name</th>
                                                        <th>Last Name</th>
                                                        <th>Email</th>
                                                        <th>Title</th>
                                                        <th>LinkedIn URL</th>
                                                        <th>Company Name</th>
                                                        <th>Company Data</th>
                                                        {/* <th>Company LinkedIn URL</th>
                                                        <th>Company Website</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {found_leads.length == 0 ? 
                                                        <tr>
                                                            <td colSpan="10" className="text-center no-results">
                                                                No Results Found
                                                            </td>
                                                        </tr> :
                                                        found_leads.slice((page - 1) * 100, page * 100).map((lead, index) => (
                                                            <tr key={index}>
                                                                <td className="table-cell"><FormCheck checked={selectedRows.includes((page - 1) * 100 + index)} onChange={() => handleLeadCheckboxClick((page - 1) * 100 + index)}/></td>
                                                                <td className="table-cell">{(page - 1) * 100 + index + 1}</td>
                                                                <td className="table-cell">{lead.first_name}</td>
                                                                <td className="table-cell">{lead.last_name}</td>
                                                                <td className="table-cell">{lead.email}</td>
                                                                <td className="table-cell">{lead.title}</td>
                                                                {/* <td className="table-cell">{lead.linkedin_url ? <a href={lead.linkedin_url} target="_blank"> {lead.linkedin_url}</a> : ""}</td>
                                                                 */}
                                                                 <td className="table-cell">
                                                                    {/* {lead.linkedin_url ? <a href={lead.linkedin_url} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} /> </a> : ""} */}
                                                                    {lead.linkedin_url ? <SocialIcon style={{"width" : "30px", "height" : "30px"}} target='_blank' url={lead.linkedin_url} /> : ""}
                                                                </td>
                                                                <td className="table-cell">{lead.employment_history.length > 0 ? lead.employment_history[0].organization_name : null}</td>
                                                                <td className="table-cell">
                                                                {lead.organization?.company_linkedin_url  ? <SocialIcon style={{"width" : "30px", "height" : "30px", marginRight : "5px"}} target='_blank' url={lead.organization?.company_linkedin_url} /> : ""}
                                                                {lead.organization?.website_url ? <SocialIcon style={{"width" : "30px", "height" : "30px"}} target='_blank' url={lead.organization?.website_url} /> : ""}
                                                                </td>
                                                                {/* <td className="table-cell">{lead.organization?.company_linkedin_url ? <a href={lead.organization?.company_linkedin_url} target="_blank"> {lead.organization?.company_linkedin_url}</a> : ""}</td>
                                                                <td className="table-cell">{lead.organization?.website_url ? <a href={lead.organization?.website_url} target="_blank"> {lead.organization?.website_url}</a> : ""}</td> */}
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                        
                                        <div ref={targetRef} className="text-end mt-4">
                                            <ButtonGroup role="group" aria-label="First group">
                                                <Button className="px-3" variant="icon btn-outline-primary" onClick={() => setPage(page - 1)} disabled={page <= 1 }>Previous</Button>
                                                {page_btns}
                                                <Button className="px-3" variant="icon btn-outline-primary" onClick={nextPage} disabled={ found_leads.length % 100 != 0 || page === 5 || page === 0 || next_loading}>
                                                    {next_loading ? <Spinner animation="border" size="sm" /> : <>Next</>}
                                                </Button>
                                            </ButtonGroup>
                                        </div>
                                        <div className="text-center mt-3">
                                        <Button variant="primary rounded-pill" onClick={handleAddToCampaign} disabled={enrich_loading || selectedRows.length === 0}>
                                                {enrich_loading? <Spinner animation="border" size="sm" /> : <><i className="fas fa-hammer"></i> <span style={{marginLeft:"5px"}}>Add To Campaign</span></>}   
                                            </Button>
                                        </div>
                                    </div> : null}


                                {pendingData().length === 0 ? "":
                                    <div>
                                        <div className="mt-5 mb-3"><h5>Review Result</h5></div>
                                        <div style={{ maxHeight: "500px", width: "100%", overflowX: "auto", overflowY: "auto" }}>
                                            <Table className="table table-hover">
                                                <thead className="sticky-header">
                                                    <tr>
                                                        <th>Full Name</th>
                                                        <th>Title</th>
                                                        <th>Email Address</th>
                                                        <th>LinkedIn</th>
                                                        <th>Email</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {pendingData().length == 0 ? 
                                                        <tr>
                                                            <td colSpan="10" className="text-center no-results">
                                                                No Results Found
                                                            </td>
                                                        </tr> :
                                                        pendingData().slice((page - 1) * 100, page * 100).map((lead, index) => (
                                                            <tr key={index}>
                                                                <td className="table-cell">{lead.first_name} {lead.last_name}</td>
                                                                <td className="table-cell">{lead.title}</td>
                                                                <td className="table-cell">{lead.email === null ? (
                                                                        <FontAwesomeIcon icon={faSpinner} spin />
                                                                        ) : (
                                                                        lead.email
                                                                        )}</td>
                                                                {/* <td >{lead.linkedin_url ? <a href={lead.linkedin_url} target="_blank"> {lead.linkedin_url}</a> : ""}</td> */}
                                                                <td className="table-cell">
                                                                    {/* {lead.linkedin_url ? <a href={lead.linkedin_url} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} /> </a> : ""} */}
                                                                    {lead.linkedin_url ? <SocialIcon style={{"width" : "30px", "height" : "30px"}} target='_blank' url={lead.linkedin_url} /> : ""}
                                                                </td>
                                                                <td className="table-cell">
                                                                {lead.email_content === null ? (
                                                                    <FontAwesomeIcon icon={faSpinner} spin />
                                                                ) : (
                                                                    <div className={`expandable-cell ${expandedIndex === index ? 'expanded' : ''}`} onDoubleClick={() => handleDoubleClick(index)}>
                                                                        {/* {lead.email_content.trim()} */}
                                                                        {lead.email_content}
                                                                    </div>
                                                                )}
                                                            </td>
                                                            <td>
                                                            <div onClick={() => handleEditClick(lead)} style={{ cursor: 'pointer', marginRight: '15px', display: 'inline-block' }} title="Edit">
                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                </div>
                                                                <div onClick={() => handleDeleteClick(lead)} style={{ cursor: 'pointer', marginRight: '15px', display: 'inline-block', color: 'red' }} title="Delete">
                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                </div>
                                                                
                                                                <div onClick={() => handleRetry(lead)} style={{ cursor: 'pointer', marginRight: '15px', display: 'inline-block', color: 'blue' }} title="Refresh">
                                                                    <FontAwesomeIcon icon={faRefresh} />
                                                                </div>

                                                                <div onClick={() => handleAccept(lead)} style={{ cursor: 'pointer', display: 'inline-block', color: 'green' }} title="Accept">
                                                                    <FontAwesomeIcon icon={faCheck} />
                                                                </div>
                                                            </td>

                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                        
                                        <div ref={targetRef} className="text-end mt-4">
                                            <ButtonGroup role="group" aria-label="First group">
                                                <Button className="px-3" variant="icon btn-outline-primary" onClick={() => setPage(page - 1)} disabled={page <= 1 }>Previous</Button>
                                                {page_btns}
                                                <Button className="px-3" variant="icon btn-outline-primary" onClick={nextPage} disabled={ found_leads.length % 100 != 0 || page === 5 || page === 0 || next_loading}>
                                                    {next_loading ? <Spinner animation="border" size="sm" /> : <>Next</>}
                                                </Button>
                                            </ButtonGroup>
                                        </div>
                                    </div>

                                    }

                                {getApprovedData().length === 0 ? "":
                                <div>
                                    <div className="mt-5 mb-3"><h5>Reviewed Data</h5></div>
                                    <div style={{ maxHeight: "500px", width: "100%", overflowX: "auto", overflowY: "auto" }}>
                                        <Table className="table table-hover">
                                            <thead className="sticky-header">
                                                <tr>
                                                    <th>Full Name</th>
                                                    <th>Title</th>
                                                    <th>Email Address</th>
                                                    <th>LinkedIn</th>
                                                    <th>Email</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {getApprovedData().length == 0 ? 
                                                    <tr>
                                                        <td colSpan="10" className="text-center no-results">
                                                            No Results Found
                                                        </td>
                                                    </tr> :
                                                    getApprovedData().slice((page - 1) * 100, page * 100).map((lead, index) => (
                                                        <tr key={index}>
                                                            <td className="table-cell">{lead.first_name} {lead.last_name}</td>
                                                            <td className="table-cell">{lead.title}</td>
                                                            <td className="table-cell">{lead.email === null ? (
                                                                    <FontAwesomeIcon icon={faSpinner} spin />
                                                                    ) : (
                                                                    lead.email
                                                                    )}</td>
                                                            {/* <td className="table-cell">{lead.linkedin_url ? <a href={lead.linkedin_url} target="_blank"> {lead.linkedin_url}</a> : ""}</td> */}
                                                            <td className="table-cell">
                                                                    {/* {lead.linkedin_url ? <a href={lead.linkedin_url} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} /> </a> : ""} */}
                                                                    {lead.linkedin_url ? <SocialIcon style={{"width" : "30px", "height" : "30px"}} target='_blank' url={lead.linkedin_url} /> : ""}
                                                                </td>
                                                            <td className="table-cell">
                                                            {lead.email_content === null ? (
                                                                <FontAwesomeIcon icon={faSpinner} spin />
                                                            ) : (
                                                                <div className={`expandable-cell ${expandedIndex === index ? 'expanded' : ''}`} onDoubleClick={() => handleDoubleClick(index)}>
                                                                    {lead.email_content.trim()}
                                                                </div>
                                                            )}
                                                        </td>
                                                        <td>
                                                                <div onClick={() => handleEditClick(lead)} style={{ cursor: 'pointer', marginRight: '15px', display: 'inline-block' }} title="Edit">
                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                </div>
                                                                <div onClick={() => handleDeleteClick(lead)} style={{ cursor: 'pointer', marginRight: '15px', display: 'inline-block', color: 'red' }} title="Delete">
                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                </div>
                                                                
                                                                <div onClick={() => handleRetry(lead)} style={{ cursor: 'pointer', marginRight: '15px', display: 'inline-block', color: 'blue' }} title="Refresh">
                                                                    <FontAwesomeIcon icon={faRefresh} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                        
                                    </div>
                                    
                                    <div ref={targetRef} className="text-end mt-4">
                                        <ButtonGroup role="group" aria-label="First group">
                                            <Button className="px-3" variant="icon btn-outline-primary" onClick={() => setPage(page - 1)} disabled={page <= 1 }>Previous</Button>
                                            {page_btns}
                                            <Button className="px-3" variant="icon btn-outline-primary" onClick={nextPage} disabled={ found_leads.length % 100 != 0 || page === 5 || page === 0 || next_loading}>
                                                {next_loading ? <Spinner animation="border" size="sm" /> : <>Next</>}
                                            </Button>
                                        </ButtonGroup>
                                    </div>

                                                <div className="text-center mt-3">
                                                <Form className="row">
                                        <div className="col-6">
                                            <Form.Group className="mb-3" controlId="campaignName">
                                                <Form.Label>Campaign Name</Form.Label>
                                                <Form.Control type="text" placeholder="Enter campaign name" value={campaignName} onChange={e => setCampaignName(e.target.value)}/>
                                            </Form.Group>
                                        </div>
                                        <div className="col-3">
                                            <Form.Group className="mb-3" controlId="campaignStartDate">
                                                <Form.Label>Campaign Start Date</Form.Label>
                                                <Form.Control type="date" placeholder="Enter campaign start date" value={campaignStartDate} onChange={e => setCampaignStartDate(e.target.value)}/>
                                            </Form.Group>
                                        </div>
                                        <div className="col-3">
                                            <Form.Group className="mb-3" controlId="campaignStartDate">
                                                <Form.Label>Campaign Start Time</Form.Label>
                                                <Form.Control type="time" placeholder="Enter campaign start time" value={campaignStartTime} onChange={e => setCampaignStartTime(e.target.value)}/>
                                            </Form.Group>
                                        </div>
                                    </Form>
                                        
                                    <Button style={{ marginLeft: "5px" }} variant="primary rounded-pill" onClick={handleStartCampaign} className="pill-button" disabled={start_loading || getApprovedData().length === 0}>
                                        {start_loading ? <Spinner animation="border" size="sm" /> : <><i className="fas fa-paper-plane"></i> <span style={{marginLeft:"5px"}}>Start Campaign</span></>} 
                                    </Button>
                                    <Button style={{ marginLeft: "5px" }}
                                                        variant="primary rounded-pill" 
                                                        onClick={handleJsonToCSV} 
                                                        className="pill-button" 
                                                        disabled={getApprovedData().length === 0}
                                                        >
                                                        {start_loading ? 
                                                            <Spinner animation="border" size="sm" /> : 
                                                            <>
                                                            <i className="fas fa-download"></i> 
                                                            <span style={{ marginLeft: "5px" }}>Export Campaign</span>
                                                            </>
                                                        }
                                                        </Button>
                                    </div>
                                </div>
                                }
                                <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Confirm Deletion</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            Are you sure you want to delete this item?
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                                                Cancel
                                            </Button>
                                            <Button variant="danger" onClick={handleDelete}>
                                                Delete
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                    {showEditModal ? <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Confirm Edit</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Form>
                                                <Form.Group controlId="formFullName">
                                                    <Form.Label>Full Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={selectedLead.name}
                                                        readOnly
                                                        style={{ backgroundColor: '#f8f9fa' }} // Light gray background for read-only
                                                    />
                                                </Form.Group>
                                                <Form.Group controlId="formTitle">
                                                    <Form.Label className="mt-3">Title</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={selectedLead.title}
                                                        readOnly
                                                        style={{ backgroundColor: '#f8f9fa' }} // Light gray background for read-only
                                                    />
                                                </Form.Group>
                                                <Form.Group controlId="formEmail">
                                                    <Form.Label className="mt-3">Email</Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        value={selectedLead.email}
                                                        readOnly
                                                        style={{ backgroundColor: '#f8f9fa' }} // Light gray background for read-only
                                                    />
                                                </Form.Group>
                                                <Form.Group controlId="formEmailContent">
                                                    <Form.Label className="mt-3">Email Content</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={5}
                                                        value={selectedLead.email_content}
                                                        onChange={handleEmailContentChange}
                                                        style={{ backgroundColor: '#ffffff' }} // White background for editable
                                                    />
                                                </Form.Group>
                                            </Form>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                                                Cancel
                                            </Button>
                                            <Button variant="primary" onClick={handleEdit}>
                                                Save
                                            </Button>
                                        </Modal.Footer>
                                    </Modal> : ""}
                                    

                                    
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    
                        
                    </Card.Body>
                    
                </Card>
            </Col>
        </Row>
        </>
    );
}
