import React, { useState } from 'react';
import { Row, Col, Image, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Card from '../components/Card';
import auth1 from '../assets/images/auth-back.png';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [resetLinkPending, setResetLinkPending] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    const handleForgotPassword = (e) => {
        e.preventDefault();
        setResetLinkPending(true);
        setEmailSent(false);

        axios.post(`${process.env.REACT_APP_API_URL}/users/forgot-password`, { email })
            .then((response) => {
                setEmailSent(true);
                toast.success('A password reset link has been sent to your email address.');
                setEmail('');
            })
            .catch((error) => {
                const errMsg = error.response?.data?.detail?.message || 'An error occurred. Please try again.';
                toast.error(errMsg);
            })
            .finally(() => {
                setResetLinkPending(false);
            });
    };

    return (
        <>
            <section className="signup-content">
                <ToastContainer />
                <Row className="m-0 align-items-center bg-white vh-100">
                    <Col md="6">
                        <Row className="justify-content-center">
                            <Col md="10">
                                <Card className="card-transparent shadow-none d-flex justify-content-center mb-0 mt-n5 auth-card">
                                    <Card.Body>
                                        <Link to="#" className="navbar-brand d-flex align-items-center mb-3">
                                            <svg width="30" className="text-primary" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="-0.757324" y="19.2427" width="28" height="4" rx="2" transform="rotate(-45 -0.757324 19.2427)" fill="currentColor" />
                                                <rect x="7.72803" y="27.728" width="28" height="4" rx="2" transform="rotate(-45 7.72803 27.728)" fill="currentColor" />
                                                <rect x="10.5366" y="16.3945" width="16" height="4" rx="2" transform="rotate(45 10.5366 16.3945)" fill="currentColor" />
                                                <rect x="10.5562" y="-0.556152" width="28" height="4" rx="2" transform="rotate(45 10.5562 -0.556152)" fill="currentColor" />
                                            </svg>
                                            <h4 className="logo-title ms-4">Email Campaign</h4>
                                        </Link>
                                        <h4 className="mb-3 text-center">Forgot Password</h4>
                                        {!emailSent ? (
                                            <Form onSubmit={handleForgotPassword}>
                                                <Form.Group controlId="formEmail" className="mt-3">
                                                    <Form.Label>Email address</Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        placeholder="Enter email"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        required
                                                    />
                                                </Form.Group>
                                                <div className="text-center mt-3">
                                                    <Button disabled={resetLinkPending} variant="primary" type="submit">
                                                        {resetLinkPending ? 'Sending...' : 'Send Reset Link'}
                                                    </Button>
                                                </div>
                                            </Form>
                                        ) : (
                                            <div className="text-center mt-3">
                                                <p>A password reset link has been sent to your email address.</p>
                                                <Button variant="link" as={Link} to="/login">
                                                    Back to Login
                                                </Button>
                                            </div>
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col md="6" className="d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden">
                        {/* Replace with your image */}
                        <Image src={auth1} className="Image-fluid gradient-main animated-scaleX" alt="background" />
                    </Col>
                </Row>
            </section>
        </>
    );
};

export default ForgotPassword;
