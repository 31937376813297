import React, { useEffect, useState } from 'react'
import HeaderSavedLeads from '../components/Headers/HeadersSavedLeads'; 
import UserTable from '../components/Table/NewTable';

export default function CampaignList() {
    const columns = [
        { title: "Full Name"},
        { title: "Role/Designation"},
        { title: "Email Address"},
        { title: "Company"},
        // { title: "LinkedIn URL"},
        // { title: "Company Data"},
    ];

    const [is_loading, setIsLoading] = useState(false);
    const [campaign_list, setCampaignList] = useState([]);
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('actio_user'));
        setIsLoading(true);
        fetch(process.env.REACT_APP_API_URL + '/get_saved_leads', {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${user.id}@@@${user.email}`, // Example: Authorization header with bearer token
                'Content-Type': 'application/json',
            },
            credentials: 'include', // Include credentials (cookies) with the request
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json(); // Parse JSON response asynchronously
        })
        .then((response) => {
            setCampaignList(
                response.map((item) => {
                    const leadData = item;
                    if (leadData){
                        const employmentHistory = leadData["employment_history"] || [];
                    return {
                        full_name : leadData["full_name"],
                        title : leadData["title"] || '', // Default to 'N/A' if title is not present
                        // email : leadData["email"] || '', // Default to 'N/A' if email is not present
                        email : "test@test.com", // Default to 'N/A' if email is not present
                        linkedin_url : leadData["linkedin_url"] || '', // Default to 'N/A' if LinkedIn URL is not present
                        employment_history : employmentHistory.length > 0 && employmentHistory[0]?.organization_name ? employmentHistory[0].organization_name : '', // Check if employment history and organization name exist
                        organization : leadData["organization"]?.website_url || '', // Default to 'N/A' if website URL is not present
                        company_linkedin_url : leadData["organization"]["company_linkedin_url"] || '', // Default to 'N/A' if company LinkedIn URL is not present
                        photo_url : leadData["photo_url"] || '', // Default to 'N/A' if company LinkedIn URL is not present,
                        organization_logo : leadData["organization"]["logo_url"]
                    };
                        
                    }
                    
                })
            );
        })

        setIsLoading(false);
    },[]);
    return (
        <>
        <HeaderSavedLeads />
        <div style={{padding : "10px"}}>
        <UserTable data={campaign_list} columns={columns}/>
        </div>
        
        </>
    )
}
