// import { createStore, combineReducers } from 'redux'
// import Mode from './setting/setting'
// export default createStore(
//     combineReducers({
//         mode: Mode
//     })
// )


import { configureStore } from '@reduxjs/toolkit';
import settingReducer from './setting/reducers';
import leadsReducer from './leadSlice';
import uploadedDataReducer from './uploadedDataSlice';
import generatedDataReducer from './generatedDataSlice';
import foundLeadsSliceReducer from './foundLeadsSlice';
import userSliceReducer from './userSlice';
export const store = configureStore({
  reducer: {
    setting: settingReducer,
    leads: leadsReducer,
    uploaded_data: uploadedDataReducer,
    generated_data: generatedDataReducer,
    found_leads: foundLeadsSliceReducer,
    user: userSliceReducer
  }
});
