import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import Default from '../layouts/default';
import FindLeads from '../views/FindLeads';
import NewFindLeads from '../views/FindLeadsNew';
import StartCampaign from '../views/BuildCampaign';
import Login from '../views/Login';
import Signup from '../views/SignUp'; // Import Signup component
import ForgotPassword from '../views/ForgotPassowrd';
import VerifyOtp from '../views/VerifyOtp';
import CampaignList from '../views/CampaignList';
import SavedLeads from '../views/SavedLeads';
import Setting from '../views/Setting';
import LeadSearchHistory from '../views/LeadSearchHistory';
import LeadSearchHistoryId from '../views/LeadSearchHistoryId';
import MailBox from '../views/MailBox';
import CampaignInbox from '../views/CampaignInbox';
import NewFindLeadsHome from '../views/FindLeadsHome';

import { setProfile } from '../store/userSlice';

function ProtectedRoute({ element }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const user = useSelector(state => state.user);
  const location = useLocation();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('actio_user'));
    if (user === null) {
      setIsLoading(false);
      return;
    }
    fetch(process.env.REACT_APP_API_URL + '/auth-check', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.id}@@@${user.email}`
      },
      credentials: 'include'
    })
      .then(response => response.json())
      .then(user => {
        if (user) {
          dispatch(setProfile(user));
        }
        setIsLoading(false);
      })
      .catch(error => {
        console.log(error.message);
        setIsLoading(false);
      });
  }, [dispatch]);

  if (isLoading) {
    return <div>Loading...</div>; // Or your loading component
  }

  return user ? element : <Navigate to="/login" state={{ from: location }} />;
}

export const DefaultRouter = [
  {
    path: '/',
    element: <Default />,
    children: [
      {
        path: '/',
        element: <ProtectedRoute element={<NewFindLeadsHome />} />
      },
      {
        path: 'search-history',
        element: <ProtectedRoute element={<LeadSearchHistory />} />
      },
      {
        path: 'build-campaign',
        element: <ProtectedRoute element={<StartCampaign />} />
      },
      {
        path: 'campaign-list',
        element: <ProtectedRoute element={<CampaignList />} />
      },
      {
        path: 'saved-leads',
        element: <ProtectedRoute element={<SavedLeads />} />
      },
      {
        path: 'setting',
        element: <ProtectedRoute element={<Setting />} />
      },
      // {
      //   path: 'lead-history',
      //   element: <ProtectedRoute element={<LeadSearchHistory />} />
      // },
      {
        path: 'lead-history/:uuid',
        element: <ProtectedRoute element={<NewFindLeads />} />
      },
      {
        path: 'mail-box',
        element: <ProtectedRoute element={<MailBox />} />
      },
      {
        path: 'mail-box/:campaignId',
        element: <ProtectedRoute element={<CampaignInbox />} />
      }
    ]
  },
  {
    path: 'login',
    element: <Login />
  },
  {
    path: 'signup', // Add the signup route here
    element: <Signup />
  },
  {
    path: 'forgot-password', // Add the signup route here
    element: <ForgotPassword />
  },
  {
    path: 'verify-otp', // Add the signup route here
    element: <VerifyOtp />
  }
];
