import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Container, Row, Col, ListGroup, Form, Button, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import './CampaignInbox.css'; // Ensure you include this CSS file
import { ToastContainer, toast } from 'react-toastify';

export default function CampaignInbox() {
    const { campaignId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [leads, setLeads] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [offset, setOffset] = useState(0);
    const [messages, setMessages] = useState([]);
    const [selectedLead, setSelectedLead] = useState(null);
    const [reply, setReply] = useState('');
    const limit = 10;

    useEffect(() => {
        // Parse query params
        const params = new URLSearchParams(location.search);
        const emailLeadMapId = params.get('emailLeadMapId');
        const emailLeadId = params.get('emailLeadId');

        // Load initial leads
        loadLeadsWithOffset(0);
        loadLeadsWithOffset(limit);

        // Fetch messages if lead is selected via query params
        if (emailLeadMapId && emailLeadId) {
            fetchMessages(emailLeadMapId, emailLeadId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    const loadLeadsWithOffset = (initialOffset) => {
        if (isLoading) return;

        setIsLoading(true);
        const user = JSON.parse(localStorage.getItem('actio_user'));
        axios.get(`${process.env.REACT_APP_API_URL}/campaign/mailbox/campaign/${campaignId}`, {
            params: {
                offset: initialOffset,
                limit,
            },
            headers: {
                Authorization: `Bearer ${user.id}@@@${user.email}`,
            },
        })
            .then(response => {
                setLeads(prevLeads => [...prevLeads, ...response.data.data]);
                if (initialOffset === limit) {
                    setOffset(prevOffset => prevOffset + limit);
                }
            })
            .catch(error => {
                console.error('Error fetching leads:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const loadMoreLeads = () => {
        if (isLoading) return;

        setIsLoading(true);
        const user = JSON.parse(localStorage.getItem('actio_user'));
        axios.get(`${process.env.REACT_APP_API_URL}/campaign/mailbox/campaign/${campaignId}`, {
            params: {
                offset,
                limit,
            },
            headers: {
                Authorization: `Bearer ${user.id}@@@${user.email}`,
            },
        })
            .then(response => {
                setLeads(prevLeads => [...prevLeads, ...response.data.data]);
                setOffset(prevOffset => prevOffset + limit);
            })
            .catch(error => {
                console.error('Error fetching leads:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleScroll = (event) => {
        const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
        if (bottom && !isLoading) {
            loadMoreLeads();
        }
    };

    const formatTime = (time) => {
        return new Intl.DateTimeFormat(navigator.language, {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short',
        }).format(new Date(time));
    };

    const fetchMessages = (emailLeadMapId, emailLeadId) => {
        const user = JSON.parse(localStorage.getItem('actio_user'));
        axios.post(`${process.env.REACT_APP_API_URL}/mailbox/campaign/${campaignId}/lead/emails`, {
            email_lead_map_id: emailLeadMapId,
            email_lead_id: emailLeadId,
        }, {
            headers: {
                Authorization: `Bearer ${user.id}@@@${user.email}`,
            },
        })
            .then(response => {
                setMessages(response.data.data);
                setSelectedLead({ emailLeadMapId, emailLeadId });

                // Update the URL with query params
                navigate(`?emailLeadMapId=${emailLeadMapId}&emailLeadId=${emailLeadId}`);
            })
            .catch(error => {
                console.error('Error fetching messages:', error);
            });
    };

    const handleReplyChange = (event) => {
        setReply(event.target.value);
    };

    const handleSendReply = () => {
        if (reply.trim() === '' || !selectedLead) return;
    
        // Extract the last message to use its data for sending a reply
        const lastMessage = messages[messages.length - 1];
        
        // Ensure there's a last message to base the reply on
        if (!lastMessage) return;
    
        const user = JSON.parse(localStorage.getItem('actio_user'));
    
        axios.post(`${process.env.REACT_APP_API_URL}/mailbox/campaign/${campaignId}/lead/emails/reply`, {
            stats_id: lastMessage.stats_id, // Use stats_id from the last message
            message_id: lastMessage.message_id, // Use message_id from the last message
            message: reply,
            time: lastMessage.time, // Current time in ISO format
        }, {
            headers: {
                Authorization: `Bearer ${user.id}@@@${user.email}`,
            },
        })
        .then(response => {
            // Add the new reply to the messages array
            setMessages(prevMessages => [
                ...prevMessages,
                {
                    email_body: reply,
                    message_id: lastMessage.message_id,
                    stats_id: lastMessage.stats_id,
                    time: new Date().toISOString(),
                    type: 'REPLY',
                },
            ]);
            setReply('');
        })
        .catch(error => {
            console.error('Error sending reply:', error);
            toast.error("Error sending reply:")
            
        });
    };

    return (
        <>
        <ToastContainer />
        
        <Container fluid className="campaign-inbox-container">
            <Row>
                {/* 20% of the screen for infinite scroll */}
                <Col xs={3} className="leads-column" style={{ overflowY: 'scroll', height: '100vh', borderRight: '2px solid #ddd' }} onScroll={handleScroll}>
                    <h5>Leads</h5>
                    <ListGroup>
                        {leads.map((lead, index) => (
                            <ListGroup.Item 
                                key={index} 
                                onClick={() => fetchMessages(lead.email_lead_map_id, lead.email_lead_id)}
                                style={{ fontWeight: lead.has_new_unread_email ? 'bold' : 'normal', cursor: 'pointer' }}
                            >
                                <div>{`${lead.first_name} ${lead.last_name}`}</div>
                                <div>{lead.email}</div>
                                <small>{formatTime(lead.last_sent_time)}</small>
                            </ListGroup.Item>
                        ))}
                        {isLoading && <p>Loading more leads...</p>}
                    </ListGroup>
                </Col>

                {/* 80% of the screen for master inbox */}
                <Col xs={9} className="chat-column" style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                    {selectedLead ? (
                        <>
                            <h3>Your master inbox</h3>
                            <p>To access the inbox, simply click on the lead.</p>
                            <div className="messages-container">
                                {messages.map((msg, index) => (
                                    <div 
                                        key={index} 
                                        className={`message ${msg.type === 'SENT' ? 'sent' : 'received'}`}
                                    >
                                        <div className="message-body">
                                            <p dangerouslySetInnerHTML={{ __html: msg.email_body }} />
                                            <small>{formatTime(msg.time)}</small>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="reply-section">
                                <InputGroup>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        value={reply}
                                        onChange={handleReplyChange}
                                        placeholder="Type your reply here..."
                                    />
                                    <Button variant="primary" onClick={handleSendReply}>Send</Button>
                                </InputGroup>
                            </div>
                        </>
                    ) : (
                        <div>
                            <h3>Your master inbox</h3>
                            <p>To access the inbox, simply click on the lead.</p>
                        </div>
                    )}
                </Col>
            </Row>
        </Container>
        </>
    );
}
