import React, {useRef, useState, useEffect } from 'react';
import { Form, Button, Spinner, Modal } from 'react-bootstrap';
import { faCompass, faUser } from '@fortawesome/free-regular-svg-icons';
import { faMagicWandSparkles, faPeopleGroup, faSitemap } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './NewSearchBar.module.css';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import { TagsInput } from "react-tag-input-component";
import { getNames } from 'country-list';
import axios from 'axios';
import avatarSearch from "../../assets/images/avatar_search.png"
import onlineBadge from "../../assets/images/online_badge.png"
import barChart from "../../assets/images/bar-chart-square.png"

const NewSearchBar = () => {
    const [countries, setCountries] = useState([]);
    const [formValues, setFormValues] = useState({
        person_locations: [],
        organization_num_employees_ranges: [],
        person_titles: [],
        q_organization_keyword_tags: []
    });
    const [searchLoading, setSearchLoading] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [totalEntries, setTotalEntries] = useState(0);
    const [magicIo, setMagicIo] = useState(false);
    const [showModal, setShowModal] = useState(false); // Modal visibility state
    const [modalPrompt, setModalPrompt] = useState(''); // Prompt input state
    const [isModalLoading, setIsModalLoading] = useState(false); // Modal loader state
    const [showSendLoading, setShowLoading] = useState(false); // Modal loader state
    const btnRef = useRef(null);
    const dummyMessage = {1: "Sure. Any other details?",
                        3 : "I'll suggest a few leads to you now."}

    const [messages, setMessages] = useState([
        { sender: 'Actio AI', text: 'Hey Bahir, how can I help you today?', timestamp: 'Today 2:20pm' },
      ]);
      const [inputText, setInputText] = useState('');
      const [isLoading, setIsLoading] = useState(false);


      const handleSendMessage = () => {
        if (inputText.trim() === '') return;
        setShowLoading(true);
      
        const newMessage = {
          sender: 'You',
          text: inputText,
          timestamp: 'Just now',
        };
      
        setMessages(prevMessages => [...prevMessages, newMessage]);
        setInputText('');
      
        // Add a one-second delay before starting the internal process
        setTimeout(() => {
          setIsLoading(true);
      
          // Add a 2-second delay before proceeding with the next steps
          setTimeout(() => {
            var newMsg = dummyMessage[messages.length];
            // alert(newMsg)
            setMessages(prevMessages => [
              ...prevMessages,
              { sender: 'Actio AI', text: newMsg, timestamp: '2 seconds ago' },
            ]);
            setIsLoading(false);
            setShowLoading(false);
      
            if (messages.length > 2) {
              handleModalSubmit();
              setTimeout(() => {
                if (btnRef.current) {
                  btnRef.current.click();
                }
              }, 1000); // 2000 milliseconds = 2 seconds
            }
          }, 2000); // 2000 milliseconds = 2 seconds
      
        }, 1000); // 1000 milliseconds = 1 second
      };

    
      const fetchEmails = async (status, page, leadId) => {
        const pageSize = 1; // Adjust the page size if needed
        const url = `${process.env.REACT_APP_API_URL}/lead_data/${leadId}?page=${page}&page_size=${pageSize}`;
        const user = JSON.parse(localStorage.getItem('actio_user'));
        try {
          const response = await fetch(url, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${user.id}@@@${user.email}`,
              'Content-Type': 'application/json'
            },
            credentials: 'include'
          });
      
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
      
          const data = await response.json();
          return data.data.data || [];
        } catch (error) {
          toast.error('Error fetching emails');
          console.error('Error fetching emails:', error);
          return [];
        }
      };
      

    const handleInputChange = (name, value) => {
        setFormValues(prevState => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSearchLoading(true);
        const allEmpty = Object.values(formValues).every(arr => arr.length === 0);
        if (allEmpty) {
            toast.error('All values are empty.');
            setSearchLoading(false);
            return;
        }

        var data = formValues;
        const user = JSON.parse(localStorage.getItem('actio_user'));
        data.page_number = 1;
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/find_people', data, {
                headers: {
                    Authorization: `Bearer ${user.id}@@@${user.email}`, // Example: Authorization header with bearer token
                }
            });

            setTotalEntries(response.data.page_data.total_entries);
            setIsFormSubmitted(true); // Set form submission state to true
            setSearchLoading(false);

            const leadId = response.data.lead_search_uuid;
            let page = 1; // Starting page for fetching emails
            let emailInterval;

            // Function to periodically fetch emails and redirect if needed
            const checkEmails = async () => {
                const emails = await fetchEmails('status', page, leadId);

                if (emails.length > 0) {
                    // Clear the interval and redirect if emails are found
                    clearInterval(emailInterval);
                    window.location.href = `/lead-history/${leadId}`;
                } else {
                    // No emails found yet, increment page for next call
                    page += 1;
                }
            };

            // Set up an interval to check emails every 8 seconds
            emailInterval = setInterval(checkEmails, 3000);


            return true; // Indicate success
        } catch (error) {
            console.log(error);
            setSearchLoading(false);
            toast.error("Search failed, Please try again");
            return false; // Indicate failure
        }
    };

    const handleModalSubmit = () => {
        setIsModalLoading(true);
        
        // Simulate a delay before updating form values
        setTimeout(() => {
            // Update the form values after 5 seconds
            setFormValues(prevState => ({
                ...prevState,
                person_locations: [
                    "United Kingdom of Great Britain and Northern Ireland" // Set desired value here
                ],
                organization_num_employees_ranges: [
                    "51, 100" // Set desired value here
                ],
                person_titles: [
                    "Sales Manager" // Set desired value here
                ],
                q_organization_keyword_tags: [
                    "SaaS" // Set desired value here
                ]
            }));
    
            // Simulate a delay to show the loading spinner
            setTimeout(() => {
                setIsModalLoading(false);
                setShowModal(false);
                // Optionally trigger form submission if needed
                // handleSubmit();
            }, 100); // Adjust delay as needed
    
        }, 100); // Delay before updating form values
    };

    const employee_range = [
        { value: '1, 10', label: '1 - 10' },
        { value: '11, 20', label: '11 - 20' },
        { value: '21, 50', label: '21 - 50' },
        { value: '51, 100', label: '51 - 100' },
        { value: '101, 200', label: '101 - 200' },
        { value: '201, 500', label: '201 - 500' },
        { value: '501, 1000', label: '501 - 1,000' },
        { value: '1001, 2000', label: '1,001 - 2,000' },
        { value: '2001, 5000', label: '2,001 - 5,000' },
        { value: '5001, 10000', label: '5,001 - 10,000' },
        { value: '10000+', label: '10,000+' }
    ];

    useEffect(() => {
        const countryNames = getNames();
        const countryOptions = countryNames.map(name => ({ value: name, label: name }));
        setCountries(countryOptions);
    }, []);

    return (
        <div className={isFormSubmitted ? styles.filterBarSubmitted : styles.filterBar}>
            <div className={isFormSubmitted ? styles.formContainerSubmittedstyles : styles.formContainerPedning}>
                {isFormSubmitted ? (
                    <div className={styles.successMessage}>
                        <div className={styles.loaderContainer}>
                            <Spinner animation="border" variant="primary" className={styles.spinner} />
                        </div>
                        <h3 className={styles.successTitle}>Search Complete!</h3>
                        <p className={styles.successText}>
                            We have found <strong>{totalEntries} potential leads</strong> for you.
                        </p>
                        <p className={styles.secondaryText}>
                            We're now processing your request. Please hang tight for a few moments...
                        </p>
                        <p className={styles.secondaryText}>
                            You will be redirected shortly.
                        </p>
                    </div>
                ) : (
                    <div className={styles.formContainerWrapper}>
                        
                        <div className={styles.formInputs}>
                            <div className={styles.formHeader}>
                                    <span>
                                    <img src={barChart} alt="Bar graph" />
                                    </span>
                                    <span className={styles.findLeadsLabel}>
                                        Find Leads
                                    </span>
                            </div>

                        <Form onSubmit={handleSubmit} className={styles.formUiContainer}>
                                <div className={styles.formUi}>
                                <Form.Group className={styles.singleForm}>
                                    <div>
                                        <Form.Label>
                                            <span className={styles.formLabel}>
                                                <FontAwesomeIcon icon={faCompass} className={styles.fontIcons} />Location
                                            </span>
                                        </Form.Label>
                                        <CreatableSelect
                                            isMulti
                                            options={countries}
                                            onChange={selectedOptions => handleInputChange('person_locations', selectedOptions.map(option => option.value))}
                                            className={styles.inputForm}
                                            styles={{
                                                multiValue: (base) => ({
                                                    ...base,
                                                    backgroundColor: 'transparent', // or choose your desired background color
                                                    border : '1px solid #D0D5DD',
                                                    borderRadius: '8px',
                                                }),
                                                multiValueLabel: (base) => ({
                                                    ...base,
                                                    color: 'inherit', // inherit text color
                                                }),
                                                multiValueRemove: (base) => ({
                                                    ...base,
                                                    ':hover': {
                                                        backgroundColor: 'transparent', // remove hover background color
                                                        color: 'red', // change remove icon color on hover if needed
                                                    }
                                                }),
                                            }}
                                        />
                                    </div>
                                </Form.Group>

                                <Form.Group className={styles.singleForm}>
                                    <div>
                                        <Form.Label>
                                            <span className={styles.formLabel}>
                                                <FontAwesomeIcon icon={faPeopleGroup} className={styles.fontIcons} />Team Size
                                            </span>
                                        </Form.Label>
                                        <Select
                                                isMulti
                                                options={employee_range}
                                                onChange={selectedOptions => handleInputChange('organization_num_employees_ranges', selectedOptions.map(option => option.value))}
                                                className={styles.inputForm}
                                                styles={{
                                                    multiValue: (base) => ({
                                                        ...base,
                                                        backgroundColor: 'transparent', // customize background color
                                                        border : '1px solid #D0D5DD',
                                                        borderRadius: '8px',
                                                    }),
                                                    multiValueLabel: (base) => ({
                                                        ...base,
                                                        color: 'inherit', // inherit text color
                                                    }),
                                                    multiValueRemove: (base) => ({
                                                        ...base,
                                                        ':hover': {
                                                            backgroundColor: 'transparent',
                                                            color: 'red',
                                                        }
                                                    }),
                                                }}
                                            />
                                    </div>
                                </Form.Group>

                                <Form.Group className={styles.singleForm}>
                                    <div>
                                        <Form.Label>
                                            <span className={styles.formLabel}>
                                                <FontAwesomeIcon icon={faUser} className={styles.fontIcons} />Roles/Designation
                                            </span>
                                        </Form.Label>
                                        <TagsInput
                                            // value={formValues.person_titles}
                                            placeHolder='Enter Titles'
                                            onChange={value => handleInputChange('person_titles', value)}
                                            separators={["Enter"]}
                                            className={styles.inputForm}
                                        />
                                    </div>
                                </Form.Group>

                                <Form.Group className={styles.singleForm}>
                                    <div>
                                        <Form.Label>
                                            <span className={styles.formLabel}>
                                                <FontAwesomeIcon icon={faSitemap} className={styles.fontIcons} />Organization Keywords
                                            </span>
                                        </Form.Label>
                                        <TagsInput
                                            // value={formValues.q_organization_keyword_tags}
                                            placeHolder='Organization Keywords'
                                            onChange={value => handleInputChange('q_organization_keyword_tags', value)}
                                            separators={["Enter"]}
                                        />
                                    </div>
                                </Form.Group>
                            </div>
                                <div className={styles.SubmitBtn}>
                                    <Button ref={btnRef} variant="primary rounded-pill" type="submit" disabled={searchLoading} className={styles.submitBtnContainer}>
                                        {searchLoading ? <Spinner animation="border" size="sm" /> : <><i className="fas fa-search"></i> <span className={{ marginLeft: "5px" }}>Search Leads</span></>}
                                    </Button>
                                </div>
                            
                        </Form>
                        </div>
                        <div className={styles.magicIo}>
                            <div className={styles.formHeader}>
                                <img src = {avatarSearch} className={styles.ActIoAgentLogo}/>
                                Chat with Acto AI
                                <span>
                                <img src = {onlineBadge} className={styles.Onlinelogo}/>

                                </span>

                            </div>
                            <div className={styles.chatContainer}>
                                <div className={styles.chatMsgContainer}>
                                {messages.map((message, index) => (
                                    message.sender === "Actio AI" ?
                                    <div className={styles.messageCard}>
                                    <img src={avatarSearch} alt="Profile" className={styles.profileImage} />
                                    <div className={styles.messageContent}>
                                        <div className={styles.messageHeader}>
                                        <span className={styles.senderName}>Actio AI</span>
                                        {/* <span className={styles.messageTimestamp}>2:30</span> */}
                                        </div>
                                        <div className={styles.messageText}>{message.text}</div>
                                    </div>
                                    </div> :
                                    <div className={styles.messageCardUser}>
                                    <div className={styles.messageContentUser}>
                                        <div className={styles.messageHeader}>
                                        <span className={styles.senderName}>You</span>
                                        {/* <span className={styles.messageTimestamp}>2:30</span> */}
                                        </div>
                                        <div className={styles.messageText}>{message.text}</div>
                                    </div>
                                    </div>

                                ))}
                                {isLoading && (
                                    <div className={styles.messageContainer}>
                                    <div className={styles.sender}>Actio AI</div>
                                    <div className={styles.messageText}>...</div>
                                    </div>
                                )}
                                </div>
                                <div className={styles.ChatSubmitBtn}>
                                <input
                                    type="text"
                                    placeholder="Write to Actio to get help"
                                    className={styles.input}
                                    value={inputText}
                                    onChange={(e) => setInputText(e.target.value)}
                                    onKeyPress={(e) => {
                                    if (e.key === 'Enter') handleSendMessage();
                                    }}
                                />
                                <button disabled = {showSendLoading} className={styles.sendButton} onClick={handleSendMessage}>Send</button>
                                </div>
                                </div>
                            </div>
                        {/* Modal Component */}
                    </div>
                )}
            </div>
            <ToastContainer />
        </div>
    );
};

export default NewSearchBar;
