import React, { useState, useEffect } from 'react';
import { Container, Button, Form, Tabs, Tab, Modal } from 'react-bootstrap';
// import './EmailManager.css';
import { faTrashCan, faSave, faRotate, faCircleCheck, faEdit,faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon  } from '@fortawesome/react-fontawesome';
import { ToastContainer, toast } from 'react-toastify';
import "./NewEmailManager.css"
import teamSize from "../../assets/images/team_size.png"
import generateMailMagic from "../../assets/images/star.png"
import externalLink from "../../assets/images/external_link.png"
import orgLogo from "../../assets/images/org_logo.png"
import linkedinSolid from "../../assets/images/linkedin_solid.png"
import DefaultProfile from "../../assets/images/default_profile.jpg"
import DefaultCompLogo from "../../assets/images/DefaultCompLogo.png"
import { MDBContainer } from "mdb-react-ui-kit";
{/* <FontAwesomeIcon icon={faLinkedinIn} /> */}


// Starts heres
const EmailManager = ({ leadId, setPendingEmails }) => {
  const [emails, setEmails] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [editing, setEditing] = useState(false);
  const [emailContent, setEmailContent] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [loadingRefetch, setLoadingRefetch] = useState(false);
  const [deleteEmailId, setDeleteEmailId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState('');
  
  const [email, setEmail] = useState(null);
  const [currentTab, setCurrentTab]= useState('user')
  const [activeTab, setActiveTab] = useState('general');
  const [userActiveTab, setUserActiveTab] = useState('general');
  const [recentNews, setRecentNews] = useState([])
  const [blogs, setBlogs] = useState([])

  const [showPopup, setShowPopup] = useState(false);
  const [inputText, setInputText] = useState('');

  const [showRewritePopUp, setShowRewritePopUp] = useState(false)
  const user = JSON.parse(localStorage.getItem('actio_user'));
  const authToken = `Bearer ${user.id}@@@${user.email}`


  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  const handleUserTabClick = (tabName) => {
    setUserActiveTab(tabName);
  };

  const handleRewrite = () =>{
    handleRefecth()
    setShowRewritePopUp(false)
    setInputText("")
  }


const generateNewEmails = async (leadId) => {
    const pageSize = 1; // Adjust the page size if needed
    const url = `${process.env.REACT_APP_API_URL}/lead_data/${leadId}/generate`;
  
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': authToken,
          'Content-Type': 'application/json',
        },
        credentials: 'include'
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

    } catch (error) {
      // toast.error('Error fetching emails');
      console.error('Error fetching emails:', error);
      return [];
    }
  };



const fetchEmails = async (status, page, leadId) => {
  const pageSize = 1; // Adjust the page size if needed
  const url = `${process.env.REACT_APP_API_URL}/lead_data/${leadId}?page=${page}&page_size=${pageSize}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': authToken,
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    fetchEmailsCount(leadId)
    if ((data.data.total_pages - data.data.page) === 7){
      generateNewEmails(leadId)
    }
    return data.data.data || [];
  } catch (error) {
    toast.error('Error fetching emails');
    console.error('Error fetching emails:', error);
    return [];
  }
};

const fetchEmailsCount = async (leadId) => {
  const pageSize = 1; // Adjust the page size if needed
  const url = `${process.env.REACT_APP_API_URL}/lead_data/${leadId}/count`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': authToken,
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    setPendingEmails(data.pending)
  } catch (error) {
    toast.error('Error fetching emails');
    console.error('Error fetching emails:', error);
    return [];
  }
};

const deleteEmail = async (id) => {
  const url = `${process.env.REACT_APP_API_URL}/email-lead/${id}`;

  try {
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Authorization': authToken,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    toast.error('Error deleting emails');
    console.error('Error deleting email:', error);
    throw error;
  }
};

const updateEmail = async (id, content) => {
  const url = `${process.env.REACT_APP_API_URL}/email-lead/${id}`;

  try {
    const response = await fetch(url, {
      method: 'PATCH',
      headers: {
        'Authorization': authToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email_content: content }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    toast.error('Error updating email');
    console.error('Error updating email:', error);
    throw error;
  }
};

const approveEmail = async (id) => {
  const url = `${process.env.REACT_APP_API_URL}/email-lead/${id}/approve`;

  try {
    const response = await fetch(url, {
      method: 'PATCH',
      headers: {
        'Authorization': authToken,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json();
   
  } catch (error) {
    toast.error('Error approving email');
    console.error('Error approving email:', error);
    throw error;
  }
};
const refetchEmail = async (id, payload) => {
    const url = `${process.env.REACT_APP_API_URL}/fetch-email-content/${id}`;
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': authToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ lead: payload }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      return await response.json();
    } catch (error) {
        toast.error('Error refetching email');
      console.error('Error refetching email:', error);
      setLoadingRefetch(false)
      throw error;
    }
  };

  const fetchCompanyRecentNews = async () => {
    const url = `${process.env.REACT_APP_API_URL}/company-news`;
    const payload = {
        company_name: email.scrapped_data.current_company_name
    }
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': authToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ lead: payload }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const resp =  await response.json();
      setRecentNews(resp.data)
    } catch (error) {
      console.error('Error refetching email:', error);
      setLoadingRefetch(false)
      throw error;
    }
  };
  

  const fetchCompanyBlog = async () => {
    const companyData = email.scrapped_data?.current_company_linked_data;
  
    // Only proceed with the API call if company data and websiteUrl are present
    if (!companyData || !companyData.websiteUrl) {
      console.log('No company data or website URL available, skipping API call.');
      return;
    }
  
    const url = `${process.env.REACT_APP_API_URL}/company-blog-post`;
    const payload = {
      company_name: companyData.websiteUrl,
    };
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: authToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ lead: payload }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const resp = await response.json();
      setBlogs(resp.data);
    } catch (error) {
      console.error('Error fetching company blog:', error);
      setLoadingRefetch(false);
      throw error;
    }
  };
  
  

  const handleReadMore = (post) => {
    setSelectedPost(post["post_text"]);
    setShowModal(true);
  };

  const handleReadMoreCompanyPost = (post) => {
    setSelectedPost(post["postText"]);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    loadEmails(1);
  }, []);

  useEffect(() => {
    if (email) {
      fetchCompanyRecentNews()
      fetchCompanyBlog()
    }
  }, [email]);

  const loadEmails = async (currentPage) => {
    const fetchWithRetry = async () => {
      const newEmails = await fetchEmails('approved', currentPage, leadId);
      
      if (newEmails.length > 0) {
        setEmail(newEmails[0]);
        setEmails([newEmails[0]]); // Single email per page
        setPage(currentPage);
        setHasMore(true);
        setEmailContent(newEmails[0].scrapped_data.email_content || '');
      } else {
        setHasMore(false);
  
        // Retry fetching emails every 2 seconds
        setTimeout(fetchWithRetry, 2000);
      }
    };
  
    // Initial fetch
    await fetchWithRetry();
  };

  const handleNextPage = () => {
    if (hasMore) {
      loadEmails(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      loadEmails(page - 1);
    }
  };

  const handleEdit = () => {
    setEditing(true);
  };

  const handleUpdate = async () => {
    setLoadingUpdate(true);
    try {
      await updateEmail(emails[0].search_history_result.id, emailContent);
      setEditing(false);
    } catch (error) {
      console.error('Error updating email:', error);
    } finally {
      setLoadingUpdate(false);
    }
  };

  const handleDelete = (emailId) => {
    setDeleteEmailId(emailId);
    setShowDeleteModal(true);
  };

  const handleDeleteEmail = async () => {
    setLoadingUpdate(true);
    try {
      await deleteEmail(deleteEmailId);
      setEmails([]);
      loadEmails(page); // Refresh emails after deletion
    } catch (error) {
      console.error('Error deleting email:', error);
    } finally {
      setLoadingUpdate(false);
      setShowDeleteModal(false);
    }
  };

  const handleApprove = async () => {
    if (!emails[0]) return;

    setLoadingApprove(true);
    try {
      await approveEmail(emails[0].search_history_result.id);
      toast.success("Email added to queue")
      loadEmails(page); // Refresh emails after approval
    } catch (error) {
      console.error('Error approving email:', error);
    } finally {
      setLoadingApprove(false);
    }
  };

  const handleRefecth = async () => {
    if (!emails[0]) return;
    // console.log(emails[0])
    setLoadingRefetch(true)
    const single_email = emails[0]
    const payload = {
        employment_history : single_email["search_history_result"]["employment_history"],
        first_name : single_email["search_history_result"]["first_name"],
        headline : single_email["scrapped_data"]["linkedin_data"]["headline"],
        id : single_email["search_history_result"]["id"],
        last_name : single_email["search_history_result"]["last_name"],
        linkedin_url : single_email["search_history_result"]["linkedin_url"],
        name : single_email["search_history_result"]["full_name"],
        organization :single_email["search_history_result"]["organization"],
        title : single_email["search_history_result"]["title"],
        email : "temp@temp.com",
        inpuText : inputText
    }
    
    // alert("hello")
    try {
        const response = await refetchEmail(payload["id"], payload)
        loadEmails(page);
        setLoadingRefetch(false)
    } catch (error) {
      console.error('Error fetching email:', error);
    } finally {
      setLoadingApprove(false);
    }
  };

  const handleRefetchPopUp = async () => {
    setShowRewritePopUp(true)
  };

  
  return (
    email ? (
        <div className='pending-email-container-wrapper'>
      <div className="pending-email-container">
        <div className="email-content-wrapper">

        <div className='email-content-upper'>
          <div className="generate-email-banner">
            <span style={{marginRight: "10px"}}>
            <img src={generateMailMagic} />
            </span>
        </div>
          <div className="email-header">
            <div className="to-user">To</div>
            <div className="user-email-info">
            <img
              src={email.search_history_result.photo_url || DefaultProfile}
              alt="Person"
              className="person-image"
              onError={(e) => {
                e.target.src = DefaultProfile; // Fallback to default image on error
              }}
            />
            <span className='person-name'>{email.search_history_result.full_name}</span>
            </div>
            
          </div>
          <textarea
            rows={16}
            value={emailContent}
            readOnly={!editing}
            onChange={(e) => setEmailContent(e.target.value)}
            className={`email-content ${!editing ? 'read-only' : ''}`}
          />

          </div>
        
      <div className='all-btn-groups'>
            {/* <div className='btn-right-group btn btn-delete' onClick={() => handleDelete(email.search_history_result.id)}>
              <FontAwesomeIcon
                
                style={{ fontSize: '20px', color: 'red', cursor: 'pointer' }}
                icon={faTrashCan}
                title="Delete Item"
              />
              Delete
            </div> */}
            {/* <div className='btn-left-group'> */}
              
            <div className='btn-right-group btn btn-delete' onClick={() => handleDelete(email.search_history_result.id)}>
              <FontAwesomeIcon
                
                style={{ cursor: 'pointer' }}
                icon={faTrashCan}
                title="Delete Item"
              />
              Delete
            </div>


              {editing ? (
                <div onClick={handleUpdate} className='btn btn-save'>
                  
                  {loadingUpdate ? <FontAwesomeIcon className="spinner" icon={faRotate} title="Saving..." /> :
                    <FontAwesomeIcon

                    icon={faSave}
                    title="Save Changes"
                  />
                  }
                  {loadingUpdate ? 'Saving' : 'Save'}
                </div>
              ) : (
                <div className='btn btn-edit'  onClick={handleEdit}>  
                <FontAwesomeIcon
                  icon={faEdit}
                  title="Edit"
                />
                <span>Edit</span>
                </div>
              )}
              <div className='btn btn-refetch' onClick={handleRefetchPopUp}>
              <FontAwesomeIcon
              
                icon={faRotate}
                title="Refetch email"
                spin={loadingRefetch}
              />
              Rewrite
              </div>
              
              <div className='btn btn-approve'  onClick={handleApprove}>
              {loadingApprove ? <FontAwesomeIcon className="spinner" icon={faRotate} title="Approving..." /> :
              <FontAwesomeIcon

              icon={faCircleCheck}
              title="Approve"
            />
              }
              Approve
              </div>
              
            </div>
          {/* </div> */}
          </div>
          <div className='right-container'>
              <div className='info-header'>
                  <div className='info-header-left'>
                  {/* Source - {currentTab === "user" ? "User" : "Company"} */}
                  
                  </div>
                  <div className='info-header-right' onClick={()=>{
                      if (currentTab === "user") {
                          setCurrentTab("company")
                      } else {
                          setCurrentTab("user")
                      }
                  }}>
                      Switch to {currentTab === "user" ? "company" : "user"}
                  </div>

                  
                
              </div>
              {currentTab === "user" ? 
              <div className='user-info-container'>
                
              <div className='comp-name-logo-location-container'>
                <div className='comp-name-white-bg'>
                      <div className='comp-logo'>
                          <img
                          className="company-image"
                          src={email.search_history_result.photo_url || DefaultProfile}
                          alt='User' 
                          onError={(e) => {
                            e.target.src = DefaultProfile; // Fallback to default image on error
                          }}
                          />
                          
                      </div>
                      <div className='comp-name-location'>
                          <div className='comp-name'>
                              {email.search_history_result.full_name}
                          </div>
                          <div className='comp-industry'>
                          {email.search_history_result.employment_history[0].title} at
                          <span className='comp-name-user-container'>
                            {email.scrapped_data?.current_company_linked_data && email.scrapped_data.current_company_linked_data.websiteUrl ? (
                              <a
                                href={
                                  email.scrapped_data.current_company_linked_data.websiteUrl.startsWith('http')
                                    ? email.scrapped_data.current_company_linked_data.websiteUrl
                                    : `https://${email.scrapped_data.current_company_linked_data.websiteUrl}`
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {email.scrapped_data.current_company_linked_data.companyName}
                              </a>
                            ) : (
                              email.scrapped_data?.current_company_linked_data?.companyName || 'Unknown Company'
                            )}
                          </span>
                          based in
                        </div>

                          <div className='comp-location'>
                          {email.scrapped_data.current_company_linked_data?.headquarter?.city},
                          <span style={{marginLeft : "3px"}}>
                          {email.scrapped_data.current_company_linked_data?.headquarter?.geographicArea}
                          </span>
                          
                          </div>
                      </div>
                  </div>
                  <div className='comp-details-container'>
                      <span className='comp-details-span'>
                      <a href={email.search_history_result.linkedin_url} target="_blank" rel="noopener noreferrer">
                                <img src ={linkedinSolid} alt="LinkedIn" className="company-icon"/>
                                  {/* <FontAwesomeIcon icon={faLinkedinIn} className="company-icon" /> */}
                      </a>
                      </span>
                      </div>
                  </div>

                  

                      <div className="tabs-container">
                          <div className="tabs">
                              <button
                              className={userActiveTab === 'general' ? 'tab active' : 'tab'}
                              onClick={() => handleUserTabClick('general')}
                              >
                              General
                              </button>
                              <button
                              className={userActiveTab === 'experience' ? 'tab active' : 'tab'}
                              onClick={() => handleUserTabClick('experience')}
                              >
                              Experiences<span className="badge">{email.scrapped_data.linkedin_data?.experiences.length}</span>
                              </button>
                              <button
                              className={userActiveTab === 'skills' ? 'tab active' : 'tab'}
                              onClick={() => handleUserTabClick('skills')}
                              >
                              Skills
                              </button>
                              {email.scrapped_data.linkedin_posts && email.scrapped_data.linkedin_posts.length > 0 ? 
                              <button
                              className={userActiveTab === 'posts' ? 'tab active' : 'tab'}
                              onClick={() => handleUserTabClick('posts')}
                              >
                              Posts
                              </button>:
                              <></>
}
                          </div>

                      <div className="tab-content">
                          {userActiveTab === 'general' && <div>
                              {/* <p>
                                  Tech Stack
                              </p> */}
                              <span className='about-header'>
                              About:
                              </span>
                              <p className="scrollable-about">
                              {email.scrapped_data?.person_summary && 
                                email.scrapped_data.person_summary.split('\n').map((line, index) => (
                                  <React.Fragment key={index}>
                                    {line}
                                    <br />
                                  </React.Fragment>
                                ))
                              }
                              </p>
                              </div>}
                              {userActiveTab === 'experience' && (
                            <div className="scrollable-about">
                              {email.scrapped_data.linkedin_data?.experiences && email.scrapped_data.linkedin_data?.experiences.length > 0 ? (
                                <ul className="experience-list">
                                  {email.scrapped_data.linkedin_data.experiences.map((post, index) => (
                                    <li key={index} className="experience-item">
                                      <div className="experience-container">
                                        <img className= "experience-company-logo" src={post?.logo || DefaultCompLogo} alt="" 
                                        onError={(e) => {
                                          e.target.src = DefaultCompLogo; // Fallback to default image on error
                                        }}/>
                                      <div className="experience-details">
                                      <div className="employment-title">    
                                              {post.employment_breakdown === undefined ? post.title : <></>}
                                          </div>
                                        
                                          <div className="company-name">
                                            <a href={post.company_linkedin_url} target="_blank" rel="noopener noreferrer">
                                              {post.company_name}
                                            </a>
                                        </div>
                                            {post.employment_breakdown === undefined ? 
                                            <div className="employment-duration">
                                              {post.location} <span className="employment-duration-company">
                                              {post.total_employment_length}   </span>                                       
                                              </div>:
                                              <MDBContainer className="employement-breakdown">
                                              <ul>
                                                {post?.employment_breakdown.map((emp_post, emp_idx) => (
                                                  <li key={emp_idx}>
                                                    <p className='employment-breakdown-title'>{emp_post.title}</p>
                                                    <p className='employment-breakdown-timeline'>{emp_post.caption}</p>
                                                    {/* <p>{emp_post.metadata}</p> */}
                                                    {/* Optionally handle the description if present
                                                    {emp_post.description.length > 0 && (
                                                      <ul>
                                                        {emp_post.description.map((desc, descIdx) => (
                                                          <li key={descIdx}>{desc}</li>
                                                        ))}
                                                      </ul>
                                                    )} */}
                                                  </li>
                                                ))}
                                              </ul>
                                            </MDBContainer>}
                                      </div>
                                      </div> 
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                <p>No experience data available</p>
                              )}
                            </div>
                          )}
                          {userActiveTab === 'skills' && <div className='scrollable-about'>
                          {email.scrapped_data?.linkedin_data?.skills && email.scrapped_data?.linkedin_data?.skills.map((item, index) => (
                              <div key={index} className="pill-box">
                              {item.title}
                              </div>
                          ))}</div>}
                          {userActiveTab === 'posts' && (
                          <div className="scrollable-about">
                              {email.scrapped_data.linkedin_posts && email.scrapped_data.linkedin_posts.length > 0 ? (
                              <ul className="post-list">
                                  {email.scrapped_data.linkedin_posts.map((post, index) => (
                                  <li key={index} className="post-item">
                                    <div>
                                    <img
                                        src={email.search_history_result.photo_url || DefaultProfile}
                                        alt="Person"
                                        className="person-image-posts"
                                        onError={(e) => {
                                          e.target.src = DefaultProfile; // Fallback to default image on error
                                        }}
                                      />
                                      <span className='person-name-posts'>{email.search_history_result.full_name}</span>
                                    </div>
                                      <p className='posts-container'>
                                      {post["post_text"].length > 150 ? (
                                          <>
                                          {post["post_text"].slice(0, 150)}...
                                          <span
                                              style={{ color: '#007bff', cursor: 'pointer', textDecoration: 'underline' }}
                                              className="read-more"
                                              onClick={() => handleReadMore(post)}
                                          >
                                              Read More
                                          </span>
                                          </>
                                      ) : (
                                          post["post_text"]
                                      )}
                                      </p>
                                  </li>
                                  ))}
                              </ul>
                              ) : (
                              <p>No posts</p>
                              )}
                              <Modal show={showModal} onHide={handleClose}>
                                <Modal.Header closeButton>
                                  <Modal.Title>Post</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  <p>{selectedPost}</p>
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button variant="secondary" onClick={handleClose}>
                                    Close
                                  </Button>
                                </Modal.Footer>
                              </Modal>
                          </div>
                          )}

                      </div>
                  </div>

              </div>:

                <div className='comp-info-container'>
                <div className='comp-name-logo-location-container'>
                <div className='comp-info-name-white-bg'>
                    <div className='comp-logo'>
                        <img
                        className="company-image"
                        src={email.search_history_result.organization.logo_url} alt="Comp Logo" />
                        
                    </div>
                    <div className='comp-name-location'>
                        <div className='comp-name'>
                            {email.scrapped_data.current_company_linked_data?.companyName}
                        </div>
                        <div className='comp-industry'>
                            {email.scrapped_data.current_company_linked_data?.industry} company based in
                        </div>
                        <div className='comp-location'>
                        {email.scrapped_data.current_company_linked_data?.headquarter?.city},
                        {email.scrapped_data.current_company_linked_data?.headquarter?.geographicArea}
                        </div>
                    </div>
                    </div>
                    <div className='comp-details-container'>
                    <span className='comp-details-span'>
                        <img className='comp-details-icon'  src={teamSize}/>
                        {email.scrapped_data.current_company_linked_data?.employeeCountRange.start}-{email.scrapped_data.current_company_linked_data?.employeeCountRange.end}
                    </span>
                    <span className='comp-details-span'>
                    <a href={email.scrapped_data.current_company_linked_data?.websiteUrl} target="_blank" rel="noopener noreferrer">
                    <img className='comp-details-icon'  src={externalLink}/>
                        Website
                        </a>
                    </span>
                    <span className='comp-details-span'>
                    <a href={email.scrapped_data.current_company_linked_data?.url} target="_blank" rel="noopener noreferrer">
                        <img src ={linkedinSolid} alt="LinkedIn" className="company-icon"/>
                    </a>
                    </span>
                    </div>

                </div>
                    
                <div className="tabs-container">
                        <div className="tabs">
                            <button
                            className={activeTab === 'general' ? 'tab active' : 'tab'}
                            onClick={() => handleTabClick('general')}
                            >
                            General
                            </button>
                            {/* <button
                            className={activeTab === 'recentHiring' ? 'tab active' : 'tab'}
                            onClick={() => handleTabClick('recentHiring')}
                            >
                            Recent hiring <span className="badge">5</span>
                            </button> */}
                            <button
                            className={activeTab === 'recentNews' ? 'tab active' : 'tab'}
                            onClick={() => handleTabClick('recentNews')}
                            >
                            Recent news
                            </button>
                            <button
                            className={activeTab === 'blogs' ? 'tab active' : 'tab'}
                            onClick={() => handleTabClick('blogs')}
                            >
                            Blogs
                            </button>
                            {email.scrapped_data.company_linkedin_posts && email.scrapped_data.company_linkedin_posts.length > 0 ?  
                            <button
                            className={activeTab === 'comp_posts' ? 'tab active' : 'tab'}
                            onClick={() => handleTabClick('comp_posts')}
                            >
                            Posts
                            </button>
                            :
                            <></>}
                            
                        </div>

                    <div className="tab-content">
                        {activeTab === 'general' && <div>
                            {/* <p>
                                Tech Stack
                            </p> */}
                            <span className='about-header'>
                            About:
                            </span>
                            <p className="scrollable-about">
                              {email.scrapped_data?.current_company_linked_data?.description && email.scrapped_data?.current_company_linked_data?.description.split('\n').map((line, index) => (
                                <React.Fragment key={index}>
                                  {line}
                                  <br />
                                </React.Fragment>
                              ))}
                            </p>
                            </div>}
                            {activeTab === 'blogs' && <div className="scrollable-about">
                            {blogs && blogs.length > 0 ? (
                            <ul className="post-list">
                                {blogs.map((news, index) => (
                                <li key={index} className="post-item">
                                    <p>
                                    <a href={news} target="_blank" rel="noopener noreferrer">
                                      {news}
                                    </a>
                                    </p>
                                </li>
                                ))}
                            </ul>
                            ) : (
                            <p>No Blogs Found</p>
                            )}
                        </div>}
                        {activeTab === 'recentNews' && <div className="scrollable-about">
                            {recentNews && recentNews.length > 0 ? (
                            <ul className="post-list">
                                {recentNews.map((news, index) => (
                                <li key={index} className="post-item">
                                  <div className='recent-news-container'>
                                    <img className = "recent-news-logo" src={`https://${news.domain}/favicon.ico`} alt="" 
                                     onError={(e) => {
                                      e.target.src = DefaultCompLogo; // Fallback to default image on error
                                    }}
                                    />
                                  <p>
                                    <a href={news.link} target="_blank" rel="noopener noreferrer">
                                      {news.title}
                                    </a>
                                    </p>
                                  </div>
                                   
                                </li>
                                ))}
                            </ul>
                            ) : (
                            <p>No Recent News</p>
                            )}
                        </div>}

                        {activeTab === 'comp_posts' && (
                          <div className="scrollable-about">
                            {email.scrapped_data.company_linkedin_posts && email.scrapped_data?.company_linkedin_posts.length > 0 ? (
                              <ul className="post-list">
                                {email.scrapped_data.company_linkedin_posts
                                  .filter((post) => post["postText"] && post["postText"].trim().length > 0) // Filter out posts with no content
                                  .map((post, index) => (
                                    <li key={index} className="post-item">
                                      <div>
                                        <img
                                          src={email.search_history_result.organization.logo_url || DefaultProfile}
                                          alt="Person"
                                          className="person-image-posts"
                                          onError={(e) => {
                                            e.target.src = DefaultProfile; // Fallback to default image on error
                                          }}
                                        />
                                        <span className="person-name-posts">
                                          {email.scrapped_data.current_company_linked_data?.companyName}
                                        </span>
                                      </div>
                                      <p className="posts-container">
                                        {post["postText"].length > 150 ? (
                                          <>
                                            {post["postText"].slice(0, 150)}...
                                            <span
                                              style={{ color: '#007bff', cursor: 'pointer', textDecoration: 'underline' }}
                                              className="read-more"
                                              onClick={() => handleReadMoreCompanyPost(post)}
                                            >
                                              Read More
                                            </span>
                                          </>
                                        ) : (
                                          post["postText"]
                                        )}
                                      </p>
                                    </li>
                                  ))}
                              </ul>
                            ) : (
                              <p>No Recent News</p>
                            )}
                            <Modal show={showModal} onHide={handleClose}>
                              <Modal.Header closeButton>
                                <Modal.Title>Post</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <p>{selectedPost}</p>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                  Close
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        )}


                        
                    </div>
                </div>

                </div>
            }
            
            
        </div>
      </div>
      <div className="pagination-controls">
        <Button onClick={handlePreviousPage} disabled={page === 1}>
          Previous
        </Button>
        <Button onClick={handleNextPage} disabled={!hasMore}>
          Next
        </Button>
      </div>
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this email?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteEmail}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showRewritePopUp} onHide={() => setShowRewritePopUp(false)}>
        <Modal.Header closeButton>
        <Modal.Title>Rewrite Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="rewriteText">
          <Form.Label>Enter instructions:</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            placeholder="Enter instruction to update the email"
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowRewritePopUp(false)}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleRewrite}>
          Rewrite
        </Button>
      </Modal.Footer>

      </Modal>
      </div>
    ) : null
  );
  
  
  
  
};

export default EmailManager;



// Older experience
// <div className="experience-content">
//                                         {/* <div className="experience-logo">
//                                             <img src={orgLogo} alt={`${post.company_name} logo`} />
//                                         </div> */}
//                                         <div className="experience-details">
//                                           <div className="company-name">
//                                             <a href={post.company_linkedin_url} target="_blank" rel="noopener noreferrer">
//                                               {post.company_name}
//                                             </a>
//                                           </div>
//                                           <div className="employment-description">
//                                           {post.description}
//                                           </div>
//                                           <div className="employment-duration">
//                                             {post.title || "Employed"} | {post.total_employment_length} | {post.location}
//                                           </div>
//                                         </div>
//                                       </div>