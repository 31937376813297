import { createSlice } from '@reduxjs/toolkit';

export const generatedDataSlice = createSlice({
  name: 'generated_data',
  initialState: [],
  reducers: {
    setGeneratedData: (state, action) => {
      return action.payload;
    },
  },
});

export const { setGeneratedData } = generatedDataSlice.actions;

export default generatedDataSlice.reducer;