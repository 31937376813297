import { createSlice } from '@reduxjs/toolkit';

export const foundLeadsSlice = createSlice({
  name: 'found_leads',
  initialState: [],
  reducers: {
    setFoundLeads: (state, action) => {
      return action.payload;
    },
  },
});

export const { setFoundLeads } = foundLeadsSlice.actions;

export default foundLeadsSlice.reducer;