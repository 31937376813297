import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import HeadersHome from '../components/Headers/HeadersHome';
import SearchBar from '../components/SearchBar/SearchBar';
import { ToastContainer } from 'react-toastify';
import EmailManager from '../components/EmailManager/EmailManager';
import { useParams } from 'react-router-dom';
import HeadersPendingEmails from '../components/Headers/HeadersPendingEmails';
import NewEmailManager from '../components/EmailManager/NewEmailManager';


export default function NewFindLeads() {
    const navigate = useNavigate();
    const location = useLocation();

    // Retrieve the 'source' query parameter from the URL
    const queryParams = new URLSearchParams(location.search);
    const initialSource = queryParams.get('source') || 'search';
    const { uuid } = useParams()

    const [source, setSource] = useState(initialSource);
    const [leadId, setLeadId] = useState(uuid);
    const [pendingEmails, setPendingEmailCount] = useState(0);

    const setPendingEmails =(count)=>{
        setPendingEmailCount(count)
    }


    return (
        <>
            <ToastContainer />
            <HeadersPendingEmails source={source} setSource={setSource} pendingEmails={pendingEmails}/>
            {/* {leadId? (
                <div></div>  // Placeholder or empty content if leadId is not empty
            ) : source === "search" ? (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <SearchBar setLeadId={setLeadId} />
                </div>
            ) : null} */}
                        
            {/* {leadId ? <EmailManager leadId={leadId} /> : ""} */}
            {leadId ? <NewEmailManager leadId={leadId}  setPendingEmails = {setPendingEmails}/> : ""}
        </>
    );
}
