import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import HeadersHome from '../components/Headers/HeadersHome';
import NewSearchBar from '../components/SearchBar/NewSearchBar';
import { ToastContainer } from 'react-toastify';
import EmailManager from '../components/EmailManager/EmailManager';
import { useParams } from 'react-router-dom';
import bgImage from "../assets/images/bg_search.png"


export default function NewFindLeadsHome() {
    const navigate = useNavigate();
    const location = useLocation();

    // Retrieve the 'source' query parameter from the URL
    const queryParams = new URLSearchParams(location.search);
    const initialSource = queryParams.get('source') || 'search';

    const [source, setSource] = useState(initialSource);
     useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('source', source);
        navigate({ search: queryParams.toString() }, { replace: true });
    }, [source, navigate, location.search]);

    return (
        <div style={{
                backgroundImage: `url(${bgImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
            }}>
            <ToastContainer />
            <HeadersHome source={source} setSource={setSource}/>
            <div style={{
                // backgroundColor: "red",
                    height: "88vh",
                    // backgroundImage: `url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}>

            
            {source === "search" ? (
                <div style={{ display: "flex" }}>
                    <NewSearchBar/>
                </div>
            ) : null}
            </div>
                        
            {/* {leadId ? <EmailManager leadId={leadId} /> : ""} */}
        </div>
    );
}
