import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form, Image } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Card from '../components/Card';
import auth1 from '../assets/images/auth-back.png';
import './VerifyOtp.css'; // Import the CSS file for additional styling
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';

const VerifyOtp = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const [token, setToken] = useState(null);
    const [isOtpValidation, setIsOtpValidation] = useState(false)

    useEffect(() => {
        // Extract query parameters
        const queryParams = new URLSearchParams(location.search);
        setToken(queryParams.get('t')); // Get 't' from query parameters
    }, [location.search]);

    const handleOtpChange = (e, index) => {
        const { value } = e.target;
        if (/^[0-9]?$/.test(value)) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            if (index < 5 && value) {
                document.getElementById(`otp-${index + 1}`).focus();
            }
        }
    };

    const handleVerifyOtp = (e) => {
        e.preventDefault();
        setIsOtpValidation(true);
        const otpValue = otp.join('');
    
        // Replace with your actual OTP verification endpoint
        axios.post(`${process.env.REACT_APP_API_URL}/users/validate-otp`, 
            { otp_code: otpValue, token }, 
            { headers: { 'Content-Type': 'application/json' } }
        )
        .then((response) => {
                // Navigate to the reset password page or perform any other success action
                setIsOtpValidation(false);
                navigate('/login'); // Uncomment this line if you want to navigate

        })
        .catch((error) => {
            // Handle network or HTTP errors
            const errorMsg = error.response?.data?.detail || 'An error occurred. Please try again.';
            toast.error(errorMsg);
            setIsOtpValidation(false);
        });
    };

    const isOtpComplete = otp.every((digit) => digit !== '');

    return (
        <>
            <section className="otp-content">
                <ToastContainer />
                <Row className="m-0 align-items-center bg-white vh-100">
                    <Col md="6">
                        <Row className="justify-content-center">
                            <Col md="10">
                                <Card className="card-transparent shadow-none d-flex justify-content-center mb-0 mt-n5 auth-card">
                                    <Card.Body>
                                        <Link to="#" className="navbar-brand d-flex align-items-center mb-3">
                                            <svg width="30" className="text-primary" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="-0.757324" y="19.2427" width="28" height="4" rx="2" transform="rotate(-45 -0.757324 19.2427)" fill="currentColor" />
                                                <rect x="7.72803" y="27.728" width="28" height="4" rx="2" transform="rotate(-45 7.72803 27.728)" fill="currentColor" />
                                                <rect x="10.5366" y="16.3945" width="16" height="4" rx="2" transform="rotate(45 10.5366 16.3945)" fill="currentColor" />
                                                <rect x="10.5562" y="-0.556152" width="28" height="4" rx="2" transform="rotate(45 10.5562 -0.556152)" fill="currentColor" />
                                            </svg>
                                            <h4 className="logo-title ms-4">Email Campaign</h4>
                                        </Link>
                                        <h4 className="mb-3 text-center">Verify OTP</h4>

                                        <Form onSubmit={handleVerifyOtp}>
                                            <Form.Group className="mt-3">
                                                <Form.Label>Enter the OTP sent to your email</Form.Label>
                                                <div className="otp-inputs text-center">
                                                    {otp.map((digit, index) => (
                                                        <Form.Control
                                                            key={index}
                                                            id={`otp-${index}`}
                                                            type="text"
                                                            maxLength="1"
                                                            value={digit}
                                                            onChange={(e) => handleOtpChange(e, index)}
                                                            className="otp-input"
                                                        />
                                                    ))}
                                                </div>
                                            </Form.Group>
                                            <div className="text-center mt-3">
                                                <Button
                                                    variant="primary"
                                                    type="submit"
                                                    disabled={!isOtpComplete || isOtpValidation} // Disable button if OTP is not complete
                                                >
                                                    Verify OTP
                                                </Button>
                                            </div>
                                        </Form>
                                        <div className="text-center mt-3">
                                            <Button variant="link" as={Link} to="/login">
                                                Back to Login
                                            </Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col md="6" className="d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden">
                        {/* Replace with your image */}
                        <Image src={auth1} className="Image-fluid gradient-main animated-scaleX" alt="background" />
                    </Col>
                </Row>
            </section>
        </>
    );
};

export default VerifyOtp;
