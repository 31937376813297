import React, { useEffect, useState } from 'react';
import { Card, Spinner, Table, Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { faChartPie } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeaderSavedLeads from '../components/Headers/HeadersSavedLeads';
import CampaignListTable from '../components/Table/CamapignListTable';

export default function CampaignList() {
    const [is_loading, setIsLoading] = useState(true);
    const [campaign_list, setCampaignList] = useState([]);
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [campaignData, setCampaignData] = useState({})

    const columns = [
        { title: "ID"},
        { title: "Name"},
        { title: "Status"},
        { title: "Created"},
        { title: "Actions"},
    ];

    useEffect(() => {
        setIsLoading(true);
        const user = JSON.parse(localStorage.getItem('actio_user'));
        axios.get(process.env.REACT_APP_API_URL + '/campaign_list', {
            headers: {
                Authorization: `Bearer ${user.id}@@@${user.email}`,
            }
        })
        .then((response) => {
            setIsLoading(false);
            setCampaignList(response.data);
        })
        .catch((error) => {
            setIsLoading(false);
            console.error('Error fetching campaign list:', error);
        });
    }, []);

    const handleDeleteCampaign = (id) => {
        // Implement delete logic here
        console.log(`Deleting campaign with ID ${id}`);
    };

    const openModal = (campaign) => {
        setSelectedCampaign(campaign);
        
        const user = JSON.parse(localStorage.getItem('actio_user'));
        axios.get(process.env.REACT_APP_API_URL + '/campaign_analytics/' + campaign.id, {
            headers: {
                Authorization: `Bearer ${user.id}@@@${user.email}`,
            }
        })
        .then((response) => {
            setIsLoading(false);
            setCampaignData(response.data);
            setShowModal(true);
        })
        .catch((error) => {
            setIsLoading(false);
            console.error('Error fetching campaign list:', error);
        });
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedCampaign(null);
    };

    return (
        <>
        <HeaderSavedLeads />
        <div style={{padding : "10px"}}>
        <CampaignListTable data={campaign_list} columns={columns} openModal={openModal}/>
        <Modal show={showModal} onHide={closeModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Campaign Details</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {showModal && (
                                        <Card>
                                            <Card.Body>
                                                <Card.Title>Campaign ID: {campaignData.id}</Card.Title>
                                                <Card.Text>
                                                    <p>Total: {campaignData.campaign_lead_stats.total}</p>
                                                    <p>Sent Count: {campaignData.sent_count}</p>
                                                    <p>Open Count: {campaignData.open_count}</p>
                                                    <p>Unique Open Count: {campaignData.unique_open_count}</p>
                                                    <p>Reply Count: {campaignData.reply_count}</p>
                                                    <p>Bounce Count: {campaignData.bounce_count}</p>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    )}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={closeModal}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>
        </div>
        
        </>
    )

    // return (
    //     <Card className="rounded-5">
    //         <Card.Body className="p-5">
    //             <h5>Campaign List</h5>
    //             <div className="mt-5">    
    //                 {is_loading ? (
    //                     <div className="d-flex align-items-center mb-5 justify-content-center">
    //                         <Spinner animation="border" variant="primary" role="status" />
    //                         <span style={{ marginLeft: "10px" }}>Loading...</span>
    //                     </div>
    //                 ) : (
    //                     <>
    //                         <Table striped bordered hover>
    //                             <thead>
    //                                 <tr>
    //                                     <th>ID</th>
    //                                     <th>Name</th>
    //                                     <th>Status</th>
    //                                     <th>Created At</th>
    //                                     <th>Updated At</th>
    //                                     <th>Actions</th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 {campaign_list.map(campaign => (
    //                                     <tr key={campaign.id}>
    //                                         <td>{campaign.id}</td>
    //                                         <td>{campaign.name}</td>
    //                                         <td>{campaign.status}</td>
    //                                         <td>{campaign.created_at}</td>
    //                                         <td>{campaign.updated_at}</td>
    //                                         <td style={{ cursor: "pointer" }}>
    //                                             <FontAwesomeIcon icon={faChartPie} onClick={() => openModal(campaign)} />
    //                                         </td>
    //                                     </tr>
    //                                 ))}
    //                             </tbody>
    //                         </Table>

    //                         {/* Modal for Campaign Details */}
    //                         <Modal show={showModal} onHide={closeModal}>
    //                             <Modal.Header closeButton>
    //                                 <Modal.Title>Campaign Details</Modal.Title>
    //                             </Modal.Header>
    //                             <Modal.Body>
    //                                 {showModal && (
    //                                     <Card>
    //                                         <Card.Body>
    //                                             <Card.Title>Campaign ID: {campaignData.id}</Card.Title>
    //                                             <Card.Text>
    //                                                 <p>Total: {campaignData.campaign_lead_stats.total}</p>
    //                                                 <p>Sent Count: {campaignData.sent_count}</p>
    //                                                 <p>Open Count: {campaignData.open_count}</p>
    //                                                 <p>Unique Open Count: {campaignData.unique_open_count}</p>
    //                                                 <p>Reply Count: {campaignData.reply_count}</p>
    //                                                 <p>Bounce Count: {campaignData.bounce_count}</p>
    //                                             </Card.Text>
    //                                         </Card.Body>
    //                                     </Card>
    //                                 )}
    //                             </Modal.Body>
    //                             <Modal.Footer>
    //                                 <Button variant="secondary" onClick={closeModal}>
    //                                     Close
    //                                 </Button>
    //                             </Modal.Footer>
    //                         </Modal>
    //                     </>
    //                 )}
    //             </div>
    //         </Card.Body>
    //     </Card>
    // );
}
