//router
// import IndexRouters from "./router/index"

//scss
import "./assets/scss/hope-ui.scss"
import "./assets/scss/custom.scss"
import "./assets/scss/dark.scss"
import "./assets/scss/rtl.scss"
import "./assets/scss/customizer.scss"
import '@fortawesome/fontawesome-free/css/all.css';
import LogRocket from 'logrocket';

// Redux Selector / Action
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// import state selectors
import { setSetting } from './store/setting/actions'

function App({children}) {
  LogRocket.init('rgpldc/actio');
  const dispatch = useDispatch()
  dispatch(setSetting())

  useEffect(() => {
    console.log("App Starts");
  }, [dispatch]);

  return (
    <div className="App">
      {/* <IndexRouters /> */}
      {children}
      
    </div>
  );
}

export default App;
