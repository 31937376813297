import React, { useState } from 'react';
import { Row, Col, Image, Button, Alert, Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Card from '../components/Card';
import auth1 from '../assets/images/auth-back.png';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import CarouselComponent from '../components/CarasoulComponet/CarouselComponent';
import googleLogo from "../assets/images/google_icon.png"


const Signup = () => {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [signUpLoading, setSignUpLoading] = useState(false)
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const handleSignup = (e) => {
        e.preventDefault();
        setSignUpLoading(true)

        if (password !== confirmPassword) {
            setPasswordsMatch(false);
            setSignUpLoading(false);
            return;
        }
        setPasswordsMatch(true);

        axios.post(`${process.env.REACT_APP_API_URL}/users`, {
            email,
            password,
            name
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            const user_token = response?.data?.token
            if (user_token){
                navigate(`/verify-otp?t=${user_token}`);
            }
            
        })
        .catch((error) => {
            const errMsg = error?.response?.data?.detail
            toast.error(errMsg)
        })
        .finally(() => {
            setSignUpLoading(false);
        });
    };

    return (
        <>
            <section className="signup-content">
            <ToastContainer />
                <Row className="m-0 align-items-center bg-white vh-100">
                    <Col md="6">
                        <Row className="justify-content-center">
                            <Col md="10">
                                <Card className="card-transparent shadow-none d-flex justify-content-center mb-0 mt-n5 auth-card">
                                    <Card.Body>
                                        {/* <Link to="#" className="navbar-brand d-flex align-items-center mb-3">
                                            <svg width="30" className="text-primary" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="-0.757324" y="19.2427" width="28" height="4" rx="2" transform="rotate(-45 -0.757324 19.2427)" fill="currentColor" />
                                                <rect x="7.72803" y="27.728" width="28" height="4" rx="2" transform="rotate(-45 7.72803 27.728)" fill="currentColor" />
                                                <rect x="10.5366" y="16.3945" width="16" height="4" rx="2" transform="rotate(45 10.5366 16.3945)" fill="currentColor" />
                                                <rect x="10.5562" y="-0.556152" width="28" height="4" rx="2" transform="rotate(45 10.5562 -0.556152)" fill="currentColor" />
                                            </svg>
                                            <h4 className="logo-title ms-3">Email Campaign</h4>
                                        </Link> */}
                                        <h3 className="mb-3 text-center">Sign Up</h3>
                                        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                                        <Form onSubmit={handleSignup}>
                                            <Form.Group controlId="formName">
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter your name"
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                    required
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="formEmail" className="mt-3">
                                                <Form.Label>Email address</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Enter email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    required
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="formPassword" className="mt-3">
                                                <Form.Label>Password</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    placeholder="Password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    required
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="formConfirmPassword" className="mt-3">
                                                <Form.Label>Confirm Password</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    placeholder="Confirm password"
                                                    value={confirmPassword}
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                    required
                                                />
                                                {!passwordsMatch && (
                                                    <Form.Text className="text-danger">
                                                        Passwords do not match
                                                    </Form.Text>
                                                )}
                                            </Form.Group>
                                            <div className="text-center mt-3">
                                                <Button
                                                disabled={signUpLoading}
                                                // variant="primary"
                                                style={{ 
                                                    backgroundColor: '#007AFF'
                                                }}
                                                type="submit"
                                                className="w-100"
                                            >
                                                Get Started
                                            </Button>
                                            </div>
                                            
                                        </Form>
                                        <div className="text-center mt-3">
                                            <Button variant="outline-primary" className='w-100'>
                                            <Image style={{ width: '20px', marginRight: '10px' }} src={googleLogo} className="Image-fluid gradient-main" alt="images" />
                                                <span>Sign up with Google</span>
                                            </Button>
                                        </div>
                                        <div className="text-center mt-3">
                                            <span>
                                                Already have an account?{' '}
                                                <Button
                                                    variant="link"
                                                    as={Link}
                                                    to="/login"
                                                    style={{ padding: 0 }} // Remove padding if needed
                                                >
                                                    Log In
                                                </Button>
                                            </span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col md="6" className="d-md-block d-none p-0 mt-n1 vh-100 overflow-hidden">
                        {/* Replace with your image */}
                        {/* <Image src={auth1} className="Image-fluid gradient-main animated-scaleX" alt="background" />
                         */}
                         <CarouselComponent />
                    </Col>
                </Row>
            </section>
        </>
    );
};

export default Signup;
