import React, { useEffect, useState } from 'react';
import { Card, Spinner, Table, Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import UserTable from '../components/Table/NewTable';


export default function MailBox() {
    const [isLoading, setIsLoading] = useState(true);
    const [campaignList, setCampaignList] = useState([]);
    const [messageCounts, setMessageCounts] = useState({});
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [campaignData, setCampaignData] = useState({});

    const navigate = useNavigate();

    const handleRowClick = (campaign) => {
        navigate(`/mail-box/${campaign.id}`);
    };
    const data = [
        {
          id: 1,
          checked: true,
          name: "Phoenix Baker",
          avatar: "url_to_avatar",
          role: "SVP",
          company: "Catalog",
          companyWebsite: "catalogapp.io",
          linkedin: "url_to_linkedin_profile",
          twitter: "url_to_twitter_profile"
        },
        {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          },
          {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          },
          {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          },
          {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          },
          {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          },
          {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          },
          {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          },
          {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          },
          {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          },
          {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          },
          {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          },
          {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          },
          {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          },
          {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          },
          {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          },
          {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          },
          {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          },
          {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          },
          {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          },
          {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          },
          {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          },
          {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          },
          {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          },
          {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          },
          {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          },
          {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          },
          {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          },
          {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          },
          {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          },
          {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          },
          {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          },
          {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          },
          {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          },
          {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          },
          {
            id: 1,
            checked: true,
            name: "Phoenix Baker",
            avatar: "url_to_avatar",
            role: "SVP",
            company: "Catalog",
            companyWebsite: "catalogapp.io",
            linkedin: "url_to_linkedin_profile",
            twitter: "url_to_twitter_profile"
          }
          
        // More user objects...
      ];

    useEffect(() => {
        setIsLoading(true);
        const user = JSON.parse(localStorage.getItem('actio_user'));
        
        // Fetch campaign list
        axios.get(process.env.REACT_APP_API_URL + '/campaign_list', {
            headers: {
                Authorization: `Bearer ${user.id}@@@${user.email}`,
            }
        })
        .then((response) => {
            setCampaignList(response.data);
            const campaignIds = response.data.map(campaign => campaign.id);
            
            // Fetch message counts for campaigns
            return axios.post(process.env.REACT_APP_API_URL + '/campaign/maibox/count', 
                { campaign_ids: campaignIds },
                {
                    headers: {
                        Authorization: `Bearer ${user.id}@@@${user.email}`,
                    }
                }
            );
        })
        .then((response) => {
            setMessageCounts(response.data.data);
            setIsLoading(false);
        })
        .catch((error) => {
            setIsLoading(false);
            console.error('Error fetching campaign list or message counts:', error);
        });
    }, []);


    return (
        <>
        <UserTable data={data} />
        
        
        </>
    );
}



// <Card className="rounded-5">
//             <Card.Body className="p-5">
//                 <h5>Campaign List</h5>
//                 <div className="mt-5">    
//                     {isLoading ? (
//                         <div className="d-flex align-items-center mb-5 justify-content-center">
//                             <Spinner animation="border" variant="primary" role="status" />
//                             <span style={{ marginLeft: "10px" }}>Loading...</span>
//                         </div>
//                     ) : (
//                         <>
//                             <Table striped bordered hover>
//                                 <thead>
//                                     <tr>
//                                         <th>ID</th>
//                                         <th>Name</th>
//                                         <th>Status</th>
//                                         <th>Created At</th>
//                                         <th>Updated At</th>
//                                         <th>New Messages</th>
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     {campaignList.map(campaign => (
//                                         <tr key={campaign.id} onClick={() => handleRowClick(campaign)} style={{ cursor: 'pointer' }}>
//                                             <td>{campaign.id}</td>
//                                             <td>{campaign.name}</td>
//                                             <td>{campaign.status}</td>
//                                             <td>{campaign.created_at}</td>
//                                             <td>{campaign.updated_at}</td>
//                                             <td>{messageCounts[campaign.id] || 0}</td>
//                                         </tr>
//                                     ))}
//                                 </tbody>
//                             </Table>

//                             {/* Modal for Campaign Details */}
                        
//                         </>
//                     )}
//                 </div>
//             </Card.Body>
// </Card>