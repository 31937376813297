import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // For React Router v6
import HeaderSavedLeads from '../components/Headers/HeadersSavedLeads'; 
import PendingTable from '../components/Table/PendingTable';

export default function LeadSearchHistory() {
    const [leadData, setLeadData] = useState([]);
    const navigate = useNavigate();

    const columns = [
        { title: "Location"},
        { title: "Employees Range"},
        { title: "Titles"},
        { title: "Keywords"},
        { title: "Date"},
    ];

    function convertUTCToLocal(utcString) {
        const utcDate = new Date(utcString + 'Z'); // Append 'Z' to indicate UTC time
        return utcDate.toLocaleString(); // Convert to local time as string
    }

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('actio_user'));
        axios.get(`${process.env.REACT_APP_API_URL}/lead_search_history`, {
            headers: {
                Authorization: `Bearer ${user.id}@@@${user.email}`, // Example: Authorization header with bearer token
            }
        })
        .then((response) => {
            setLeadData(response.data);
        })
        .catch((error) => {
            console.log(error);
        });
    }, []);

    const handleRowClick = (id) => {
        
        navigate(`../build-campaign?id=${id}`); 
    };

    return (
        <>
        <HeaderSavedLeads />
        <div style={{padding : "10px"}}>
        <PendingTable data={leadData} columns={columns}/>
        </div>
        
        </>
    );
}
