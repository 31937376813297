import React, { useState } from "react";
import { Image } from 'react-bootstrap';
import linkedInClip from "../../assets/images/clip_linkedin.png"
import linkedInLogo from "../../assets/images/linkedin.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowLeft, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import DefaultProfile from "../../assets/images/default_profile.jpg"
import linkedinSolid from "../../assets/images/linkedin_solid.png"
import externalLink from "../../assets/images/external_link.png"

const UserTable = ({ data, columns }) => {
  console.log(data, "000")
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;

  // Calculate pagination details
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(data.length / itemsPerPage);


  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  return (
    <div style={styles.tableContainer}>
      <table style={styles.table}>
        <thead>
        <tr>
            {columns.map((column, index) => (
                <th key={index} style={styles.tableHeader}>{column.title}</th>
            ))}
            </tr>
        </thead>
        <tbody>
          {currentItems.map((user, index) => (
            <tr
            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#F9FAFB'}
            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''} 
            key={user.id}>
              {/* <td style={styles.td}>
                <input type="checkbox" checked={user.checked} style={{ marginRight: "5px" }} />
                {indexOfFirstItem + index + 1}
              </td> */}
              <td style={styles.td}>
                {/* <img src={user.avatar} alt={user.name} style={styles.avatar} /> */}
                <span style={styles.compName} >
                <img
                  src={user.photo_url || DefaultProfile}
                  alt=""
                  className="person-image"
                  style={{
                    width: "40px",
                    height: "40px",
                    marginRight: "5px",
                  }}
                  onError={(e) => {
                    e.target.src = DefaultProfile; // Fallback to default image on error
                  }}
                />
                <div style={styles.userInfo}>
                {user.full_name}
                <a href={user.linkedin_url} target="_blank" rel="noopener noreferrer">
                                <img src ={linkedinSolid} alt="LinkedIn" className="company-icon"/>
                                  {/* <FontAwesomeIcon icon={faLinkedinIn} className="company-icon" /> */}
                      </a>
                </div>

                
                </span>
               
              </td>
              <td style={styles.td}>{user.title}</td>
              <td style={styles.td}>{user.email}</td>
              <td style={styles.td}>
                <div style={{
                  display : "flex",
                }}>
                    <div>
                    <img
                  src={user.organization_logo }
                  alt="Person"
                  className="person-image"
                  style={{
                    "width" : "50px",
                    "height" : "50px",
                    marginRight : "5px"
                  }}
                />

                    </div>
                    <div>
                    <span style={styles.compName}>
                    {user.employment_history}
                    </span>

                  <a href={user?.organization} target="_blank" rel="noopener noreferrer">
                    <img className='comp-details-icon'  src={externalLink}/>
                        </a>
                  
                        <a style={{marginLeft : "5px"}} href={user.company_linkedin_url} target="_blank" rel="noopener noreferrer">
                                <img src ={linkedinSolid} alt="LinkedIn" className="company-icon"/>
                                  {/* <FontAwesomeIcon icon={faLinkedinIn} className="company-icon" /> */}
                      </a>
                  
                  {/* <a href={user.organization}>{user.organization}</a> */}
                    </div>
                    
                </div>
              </td>
              {/* <td style={styles.td}>
                {user.linkedin_url ?
                <a href={user.linkedin_url} target="_blank" rel="noreferrer" style = {styles.linkedinButton}>
                <span>
                  <Image style={{ width: '15px', marginRight: '5px' }} src={linkedInClip} className="Image-fluid gradient-main" alt="images" />
                  </span>
                  LinkedIn
              </a>:
              <div >not available</div> }
                

              </td> */}
              {/* <td style={styles.td}>
                {user.company_linkedin_url ?  <a href={user.company_linkedin_url} target="_blank" rel="noreferrer">
                <Image style={{ width: '45px', marginRight: '5px' }} src={linkedInLogo} className="Image-fluid gradient-main" alt="images" />
                </a> :
                <div >not available</div>}
               
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
      <div style={styles.pagination}>
        <button onClick={handlePreviousPage} disabled={currentPage === 1} style={styles.button}>
            <FontAwesomeIcon icon={faArrowLeft} />
            <span style={{marginLeft : "8px"}}>
            Previous
            </span>
          
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button onClick={handleNextPage} disabled={currentPage === totalPages} style={styles.button}>
        <span style={{marginRight : "8px"}}>
            Next
            </span>
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
    </div>
  );
};

const styles = {
    tableContainer: {
        width: "100%",
        margin: "0 auto",
        // borderRadius: "8px", // Rounded edges for the container
        // overflow: "hidden",   // Ensures the table edges are rounded
        // boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Optional: Adds a subtle shadow
        padding : "10px"
      },
      table: {
        width: "100%",
        // borderCollapse: "collapse",
        borderRadius: "10px", // Rounded edges for the table
        // overflow: "hidden",  // Ensures the table edges are rounded
        border : "1px solid #EAECF0"
      },
      tableHeader: {
    // backgroundColor: "#f4f4f4",
    textAlign: "left",
    padding: "10px",
    fontSize: "0.9rem",
    color : " #475467",
    borderBottom : "1px solid #EAECF0"
  },
  td: {
    padding: "10px",
    borderBottom: "1px solid #ddd",
    fontSize: "0.9rem",
    maxWidth: "150px",
    wordWrap: "break-word", // Ensures long words wrap to the next line
    whiteSpace: "normal",   // Allows content to wrap normally
    color : "#475467"
  },
  avatar: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    marginRight: "10px",
  },
  icon: {
    width: "20px",
    height: "20px",
    marginRight: "10px",
  },
  pagination: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // margin: "10px 0",
    border : "1px solid #EAECF0",
    padding: "15px 10px"
  },
  button: {
    padding: "5px 10px",
    cursor: "pointer",
    backgroundColor: "#fff",
    color: "#000",
    border: "1px solid #EAECF0",
    borderRadius: "4px",
    padding:"5px 10px"
  },
  compName : {
    fontWeight : "500",
    color : "#101828",
    display : "flex",
    allignItems : "center",
    marginBottom : "5px"
  },
  userInfo:{
display : "flex",
flexDirection : "column"
  },
  linkedinButton: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '5px 10px',
    fontSize: '0.8rem',
    color: '#0077B5', // LinkedIn blue color
    backgroundColor: '#E8F4FC', // Light blue background
    border: '1px solid #0077B5',
    borderRadius: '20px', // Rounded corners
    textDecoration: 'none', // Remove underline from the link
    fontWeight: '500',
  },
  notAvailabale: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '5px 10px',
    fontSize: '0.8rem',
    color: '#0077B5', // LinkedIn blue color
    backgroundColor: '#E8F4FC', // Light blue background
    border: '1px solid #0077B5',
    borderRadius: '20px', // Rounded corners
    textDecoration: 'none', // Remove underline from the link
    fontWeight: '500',
  },
};

export default UserTable;
