import { useEffect, memo, Fragment, useContext } from "react";
import { useLocation, Outlet } from "react-router-dom";

//react-shepherd
import {  ShepherdTourContext } from "react-shepherd";

//react-bootstrap
import { Link } from "react-router-dom";

//sidebar
import Sidebar from "../components/partials/dashboard/SidebarStyle/sidebar";

//default
// import {DefaultRouter} from '../../router/default-router'

//seetingoffCanvas
import SettingOffCanvas from "../components/setting/SettingOffCanvas";

import Loader from "../components/Loader";

// Import selectors & action from setting store
import * as SettingSelector from "../store/setting/selectors";

// Redux Selector / Action
import { useSelector } from "react-redux";


const Tour = () => {
  const tour = useContext(ShepherdTourContext);
  const { pathname } = useLocation();
  useEffect(() => {
    if (
      pathname === "/dashboard" &&
      sessionStorage.getItem("tour") !== "true"
    ) {
      tour?.start();
    }
  });
  return <Fragment></Fragment>;
};

const Default = memo((props) => {
  const appName = useSelector(SettingSelector.app_name);
  useEffect(() => {});

  return (
    <Fragment>
      <Loader />
      <div style={styles.container}>
        <Sidebar app_name={appName} />
        <main style={styles.mainContent}>
          <div>
            <Outlet />
          </div>
        </main>
      </div>
    </Fragment>

  );
});
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: '100vh',
  },
  mainContent: {
    flex: 1,
    // padding: '20px',
    // overflowY: 'auto',
    background: "white",
    maxHeight: "100vh",
  },
};


Default.displayName = "Default";
export default Default;
