import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Image, Button } from 'react-bootstrap';
import loginBackground from '../../assets/images/login_background.jpg';
import loginBackground2 from '../../assets/images/carasoul.png';
import styles from './CarouselComponent.module.css';

const handleDragStart = (e) => e.preventDefault();

const items = [
    <div className={styles.carouselItem} key="item1">
        <Image src={loginBackground} onDragStart={handleDragStart} className={`${styles.image} Image-fluid`} alt="images1" />
        <div className={styles.carouselContent}>
            <h3 className={styles.title}>Acto has saved us thousands of hours of work.</h3>
            <p className={styles.subtitle}>Lula Meyers, Product Manager, Hourglass</p>
        </div>
    </div>,
    <div className={styles.carouselItem} key="item2">
        <Image src={loginBackground2} onDragStart={handleDragStart} className={`${styles.image} Image-fluid`} alt="images2" />
        {/* <div className={styles.carouselContent}>
            <h3 className={styles.title}>We're able to spin up projects faster and take on more clients.</h3>
            <p className={styles.subtitle}>Lula Meyers, Product Manager, Hourglass</p>
        </div> */}
    </div>,
];

const CarouselComponent = () => {
    return (
        <div className={styles.carouselContainer}>
            <AliceCarousel
                mouseTracking
                items={items}
                autoPlay
                infinite
                autoPlayInterval={2000}
                animationDuration={1500}
                // renderPrevButton={() => <Button className={styles.carouselPrev}>{"<"}</Button>}
                // renderNextButton={() => <Button className={styles.carouselNext}>{">"}</Button>}
            />
        </div>
    );
};

export default CarouselComponent;
